ul li{list-style: none;}
.docBody {
    background: rgb(245, 247, 250);
    overflow: hidden;
    min-height: 100vh;
}
.documentmain-wrapper {
    width: 100%;
    margin: auto;
    position: relative;
    padding: 0 50px;
}
.doc_header {background: #fff;padding: 5px 0px;}
.hedleft {display: inline-block;width: 49%;vertical-align: middle;}
.doclogo {display: inline-block;width: 40%;vertical-align: middle;padding-left: 15px;}
.doclogo img {
}
.search_txt {display: inline-block;width: 59%;vertical-align: middle;position: relative;}
input.cus_text {font-size: 12px;padding-left: 30px;background: #f5f7fa;border: none;height: 30px;width: 185px;color: #8f8f8f;}
span.serchicons {position: absolute;left: 7px;font-size: 12px;top: 6px;}
.headright {display: inline-block;width: 49%;vertical-align: middle;text-align: right;}
.headright ul {margin-bottom: 0px;}
.headright ul li {display: inline-block;vertical-align: middle;position: relative;padding: 0px 10px;color: #8f8f8f;}
.headright ul li .righticons {
}
span.profilepic {width: 30px;overflow: hidden;display: inline-block;border-radius: 50%;}
.profilepic i{
    position: absolute;
    top: 3px;
    right: -3px;
}
.bodywrapperleft {
    position: absolute;
    bottom: 21px;
    background: #fff;
    width: 255px;
    margin-top: 20px;
    float: left;
    top: 0px;
}

.bodywrapperleft ul{
    padding-left: 0px;
}
.bodywrapperleft ul li{
    font-size: 15px;
    color: #8f8f8f;
    padding: 10px;
    padding-left: 25px;
    cursor: pointer;
}
.bodywrapperleft ul li .listTxt{
    display: inline-block;
    padding-right: 15px;
}
.activelist{
    background: #071a55;
    color: #fff !important;
}
.bodywrapperright {
    display: inline-block;
    padding: 20px 30px;
    width: 83%;
    margin-left: 255px;
}
iframe#mainIframe {
    width: 100%;
    height: 86vh;
    -webkit-appearance: meter;
}
.doc_detailwrapper {
    padding: 0px 20px 10px;
    background: #fff;
    min-height: 100vh;
    
}
.toptxt_title {
    display: inline-block;
    color: #01155a;
    font-weight: 600;
    font-size: 15px;
}
.newDoc, .clearSearchBtn {
    display: inline-block;
    margin-left: 15px;
    vertical-align: middle;
    background: #071a55;
    color: #fff;
    padding: 7px 15px;
    border-radius: 4px;
    font-size: 12px;
    cursor: pointer;
}
span.docplusiocns {
    padding-right: 10px;
}
.topdocheader {
    margin-bottom: 10px;
    left: 0;
    top: 0;
    right: 0;
    background: #fff;
    z-index: 1;
    padding: 10px 0px;
}
.selectall {
    margin: 15px 0px;
}
.filecontainer{}
.filelist {
    margin-bottom: 15px;
    border-bottom: solid 1px #ccc;
    padding-bottom: 10px;
    position: relative;;
}
.checkbonconatiner{
    display: inline-block;
    vertical-align: middle;
    width: 5%;
    cursor: default;
}
.filedetailecontainer{
    display: inline-block;
    vertical-align: middle;
    width: 79%;
}
.fileimg{
    display: inline-block;
    vertical-align: middle;
    font-size: 30px;
    background: #f5f7fa;
    padding: 0px 10px;
}
.folderimg{
    display: inline-block;
    vertical-align: middle;
    font-size: 30px;
    background: #f5f7fa;
    padding: 0px 10px;
    cursor: default;
}
.fileimg i {
    color: #e2574c;
}
.folderimg i{
    color: #071a55;
}
.fldetails{
    display: inline-block;
    vertical-align: middle;
}
.filename{
    font-size: 13px;
    color: #071a55;
    font-weight: 600;
    cursor: pointer;
}
.fileactivity{color: #8f8f8f;}
.actionbtn{
    float: right;
    vertical-align: middle;
    width: 100px;
    text-align: right;
    position: relative;
    padding: 12px 20px;
}

.actionbtn i{
    cursor: pointer;
    font-size: 17px;
}
.acitems{
    position: absolute;
    width: 152px;
    right: 8px;
    text-align: left;
    box-shadow: 1.5px 1.5px 1.5px 1.5px #cccccc;
    z-index: 999;
    background: #fff;
}
.atitems-hide{
    display: none;
}
.acitems ul {
    padding-left: 0px;
}
.acitems ul li{
    color: #8f8f8f;
    padding: 7px 15px;
    cursor: pointer;
}
.acitems ul li:hover{
    background: #071a55;
    color: #fff;
}
.acitems ul li:hover a{

    color: #fff;
}
.acitems ul li .aciconsicons{
    padding-right: 9px;
}
.acitems ul li a:hover{text-decoration: none;}
.acitems ul li a{ color: #8f8f8f;}
/* custome check box */
/* The container */
.doc_detailwrapper .cus_ckechbox {
    position: relative;
    cursor: pointer;
    font-size: 12px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    padding-left: 22px;
    font-weight: 600;
    line-height: 12px;
    display: none;
  }

  /* Hide the browser's default checkbox */
  .doc_detailwrapper .cus_ckechbox input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  /* Create a custom checkbox */
  .doc_detailwrapper .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 12px;
    width: 12px;
    border: solid 1px #8f8f8f;
    box-shadow: 1px 0px 1px 0px #8f8f8f;
  }

  /* On mouse-over, add a grey background color */
  .cus_ckechbox:hover input ~ .checkmark {
  }

  /* When the checkbox is checked, add a blue background */
  .cus_ckechbox input:checked ~ .checkmark {
    background-color: #071a55;
    box-shadow: none;
  }

  /* Create the checkmark/indicator (hidden when not checked) */
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }

  /* Show the checkmark when checked */
  .cus_ckechbox input:checked ~ .checkmark:after {
    display: block;
  }

  /* Style the checkmark/indicator */
  .cus_ckechbox .checkmark:after {
    left: 3px;
    top: 1px;
    width: 4px;
    height: 8px;
    border: solid white;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
/* end of custome check box */


.popupwrapper {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, .8);
    z-index: 9999;
}
.popupbody{
    position: absolute;
    left: 50%;
    top: 0;
    bottom: 0;
    transform: translateX(-50%);
    background: #fff;
    width: 50%;
}
.clsbtn{
    cursor: pointer;
}
.poptitletxt{
    color: #00145a;
    font-weight: 600;
    margin: 10px;
    font-size: 20px;
}
.poptitleHead {
  border-bottom: solid 1px #ccc;
}
iframe#pdfviewer {
    width: 100%;
    height: 100%;
}
.actionbody {
    position: absolute;
    background: #fff;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    padding: 20px;
    border-radius: 10px;
    width: 28%;
}
#sessionExpire .actionbody {
    width: 35%;
}
.acttxtx {
    padding: 10px 0px;
    color: #5b5c6c;
}
.fltxt{
    color: #5b5c6c;
    padding: 10px 0px 0px;
}
.fldwrapper{
    background: #f7f7f7;
    padding: 5px 10px;
    position: relative;
    cursor: pointer;
}
.folderSelect{
  padding: 0 10px;
}
.fldwrapperactive{
    background:#ebebeb;
}
.fldicons{}
.fldicons i{
    padding-right: 10px;
}
.fldcheck{
    position: absolute;
    right: 10px;
    display: none;
}
.movebtn{
    float: right;
    background: #00145a;
    color: #fff;
    border-radius: 5px;
    padding: 5px 15px;
    margin-top: 20px;
    cursor: pointer;
}
.trashbtn{
    float: right;
    background: #00145a;
    color: #fff;
    border-radius: 5px;
    padding: 5px 15px;
    margin-top: 20px;
    cursor: pointer;
}
.cancletxt{
    float: right;
    width: 139px;
}
.claceltxt{
    float: left;
    margin-top: 26px;
    cursor: pointer;
}
#FolderContainer{}
#trashContainer{}
span.bacArrow {
    padding-right: 14px;
}
div#restore {
    background: #071a55;
    color: #fff;
    position: absolute;
    left: 50%;
    width: 25%;
    transform: translatex(-50%);
    z-index: 9;
    padding: 4px 10px;
    border-radius: 5px;
    text-align: center;
    top: 20px;
}
.emptytrashconatient{position: relative;}
.emptytrashconatientbody {
    text-align: center;
    width: 48%;
    margin: auto;
    font-size: 13px;
    color: #949090;
}
span.empTricon {
    font-size: 50px;
}
.deleteDocIcon, .restoreDocIcon {
  margin-left: 10px;
}
.deleteDocIcon :hover, .restoreDocIcon :hover {
  color: gray;
  cursor: pointer;
}
.removeAlert {
  display: none;
}
.bradecum {
    font-size: 20px;
}
.leftarrownew {
    cursor: pointer;
    display: inline-block;
    vertical-align: middle;
}
span#totalDocument {
    display: inline-block;
    vertical-align: middle;
    padding-right: 5px;
}
span#fldname {
    display: inline-block;
    vertical-align: middle;
    padding: 0px 10px;
}
span.minus-arrow {
    display: inline-block;
    vertical-align: -3px;
    padding-right: 15px;
}
span#totalDocument {
    display: inline-block;
    vertical-align: middle;
    padding-right: 5px;
}
span.doc_txt {
    display: inline-block;
    vertical-align: middle;
}

.hide{
    display: none;
    padding: 15px 0px;
}
span.fldname {
    cursor: pointer;
}
div#bradList{
    display: inline-block;
    padding-right: 15px;
}
.root_txt{
    cursor: pointer;
}
span.root_txt.activelist {
    background: transparent;
    color: #071a55 !important;
}
#bradecum{
    display: none;
}
.response-error {
    color: #BD3330;
    margin-left: 5px;
    margin-top: 5px;;
    font-size: 80%; 
}
.uploadAction .poptitleHead {
    margin-bottom: 20px;
}
.uploadOption {
    margin-right: 5px;
}
.modify-btn {
    margin-right: 10px;
}
.fileName-error.invalid {
    margin-top: 45px;
}
.custommanubar{
    padding: 0px 50px;
}
header{
    background: #fff;
}
.deleteclosebtn{
    position: absolute;
    font-size: 35px;
    cursor: pointer;
    color: #00145a;
    -webkit-text-stroke: 5px #fff;
    top: 10px;
    right: 8px;
}
#deleteDoc{
    display: none;
}
.actBtn {
    margin-right: 5px;
}
.search_txt {
    top: -50px;
    left: 450px;
    z-index: 999;
    width: 200px;
}
.searchtxt {
    font-size: 20px;
}
.searchTab {
    padding-top: 15px;
    margin-bottom: 10px;
}
.clearSearch {
    position: absolute;
    left: 165px;
    font-size: 12px;
    top: 7px;
    cursor: pointer;
}
.upload_files input{
    overflow: hidden;
}

button.session_cnt_btn {
    border: none;
    background: #091459;
    color: #fff;
    padding: 10px 15px;
    font-size: 15px;
    margin: 0px 10px;
}
.btn_wrapper_session{
    text-align: center;
}
.disable-btn {
    border: solid 1px gray !important;
    background: gray !important;
}
#sessionExpire .poptitleHead {
    text-align: center;
}
#sessionExpire .poptitletxt {
    font-size: 18px;
}
