.mainloginWrapper {
    position: relative;
    width: 450px;
    margin: auto;
    padding-top: 110px;
}
.logo img {
    margin: auto;
    width: 200px;
}
.logo {
  text-align: center;
}
h2.logtxt {
    color: #0f2363;
    font-weight: 600;
    text-align: center;
    margin-top: 0px;
}
.innerwrapper {
    box-shadow: 0.5px 0.5px 4.5px 1.5px #ccc;
    padding: 50px;
    display: block;
    margin-top: 40px;
    margin-bottom: 70px;
}
button.logBtn {
    width:100%;
    background: #0046FF;
    color: #fff;
    border: none;
    font-size: 20px;
    padding: 5px 0px;
    margin: 10px 0px;
    height: 65px;
    border-radius: 5px;
    font-weight: 600;
}
span.forgerpasss {
    float: right;
}
a.linkbtn {
    font-size: 13px;
    font-weight: 500;
    color: #031859;
    text-decoration: underline;
    cursor: pointer;
}
span.remme {
    font-size: 15px;
}
span.remme input {
    margin-right: 10px;
    display: inline-block;
    vertical-align: -2px;
    margin-right: 5px;
    cursor: pointer;
}
.remme label {
  cursor: pointer;
}
.regtxt {
    font-size: 13px;
    text-align: center;
}
a.gerbtn {
    color: #0d1f61;
    font-weight: 500;
    text-decoration: underline;
    cursor: pointer;
}
.lohptxt {
    color: #ff0057;
    text-align: center;
    height: 20px;
    opacity: 0;
}
.mainloginWrapper .form-group {
    margin-bottom: 30px;
    position: relative;
}
span.eyeicons {
    position: absolute;
    top: 6px;
    right: 11px;
    cursor: pointer;
    color: #756f6f;
    font-size: 17px;
}
.invalid {
  color: #BD3330;
  margin-top: 5px;
  margin-left: 5px;
  font-size: 80%;
}
.pointer {
  cursor: pointer;
}
.hide-leftnav .mar-lf-auto{
    display: none;
}
.hide-leftnav header .navbar-light .navbar-brand{
    margin: auto;
}
.hide-leftnav footer{
    display: none;
}
.hide-leftnav .mainloginWrapper .logo{
    display: none;
}
.text-center{
    text-align: center !important;
  }
.custom_hide{
    display: none !important;
}
.resendlink{
    padding-top: 10px;
}
  .bg-light{
      background: #fff !important;
  }
