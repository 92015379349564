@font-face {
  font-family: 'gilroy';
  font-style: normal;
  font-weight: 400;
  src: url('gilroy.eot'); /* IE9 Compat Modes */
  src: local('gilroy'), local('sans-serif'),
       url('gilroy.eot') format('woff2'), /* Super Modern Browsers */
       url('gilroy.woff') format('woff'), /* Modern Browsers */
       url('gilroy.ttf') format('truetype'), /* Safari, Android, iOS */
  }
body{
   padding: 0px;
   margin: 0px;
   font-family: 'gilroy', sans-serif;
   font-weight: 400;
   color: #00145a;
}
a:focus,a:hover,button:focus,button:hover{
   outline: 0!important;
}
a:focus,a:hover,button:focus,button:hover{
  text-decoration: none!important;
}
.pad-0{
   padding:0px!important;
}
.color-pink{
   color: rgb(235, 77, 75)!important;
   
}
.btnBlue{
   border: 0px none rgb(82, 104, 219);
   background-color: rgb(82, 104, 219);
background-image: -webkit-linear-gradient(59deg, rgba(215, 178, 233, 0.4) 0%, rgba(83, 105, 220, 0.4) 100%);
background-image: -o-linear-gradient(59deg, rgba(215, 178, 233, 0.4) 0%, rgba(83, 105, 220, 0.4) 100%);
background-image: linear-gradient(31deg, rgba(215, 178, 233, 0.4) 0%, rgba(83, 105, 220, 0.4) 100%);
-webkit-transition: all 0.3s ease 0s;
-o-transition: all 0.3s ease 0s;
transition: all 0.3s ease 0s;
color: rgb(255, 255, 255);
}
.btnBlue:hover{
background-color: #fff !important;
border: solid 1px #fff !important;
}
.btnTransparent{
   border: 1px solid;
    border-top-color: currentcolor;
    border-right-color: currentcolor;
    border-bottom-color: currentcolor;
    border-left-color: currentcolor;
   -webkit-transition: all 0.3s ease 0s;
   -o-transition: all 0.3s ease 0s;
   transition: all 0.3s ease 0s;
   background-color: transparent;
   color: rgb(81, 103, 219);
}
.mar-lf-auto{
  margin-left: auto;
  float: right;
}
header {
  position: relative;
  top: 0px;
  left: 0px;
  width: 100%;
  transition: all 0.35s ease-in-out 0s;
  padding: 30px 0px;
  z-index: 9;
}
.custom_header{
 background:rgba(255, 255, 255, .94);
 border-bottom: 1px solid #bebfc0;
}
header .navbar-light .navbar-brand{
   margin-right: 166px;
}
header .navbar-light .navbar-brand img{
   max-width: 130px;
}
header .navbar-light .navbar-nav .nav-link{
   padding: 5px;
   font-size: 16px;
   font-family: 'Gilroy', sans-serif;
   font-weight: 400;
   -webkit-transition: all 0.15s ease-in-out 0s;
   -o-transition: all 0.15s ease-in-out 0s;
   transition: all 0.15s ease-in-out 0s;
   color: rgb(52, 61, 72);
}

header .navbar-light .navbar-nav  .nav-item.active .nav-link,header .navbar-light .navbar-nav .nav-link:hover{
   color: rgb(235, 77, 75);
}
header .navbar-light .navbar-nav  .nav-item{
   padding-right:24px; 
}
.topBannerWrap{
   padding-top: 210px;
   padding-bottom: 20px;
   background-image: url("../images/bannerBg.jpg");
   background-size: cover;
   background-position: center center;
   background-repeat: no-repeat;
   background-attachment: fixed;
   min-height: 100vh;
   position: relative;
   overflow: hidden;
}

.topBannerWrap .discountInfo{
   display: inline-block;
   border-radius: 4em;
   border: 1px solid rgb(241, 244, 246);
   padding: 6px 25px;
   -webkit-box-shadow: rgba(22, 53, 76, 0.05) 0px 7px 25px 0px;
           box-shadow: rgba(22, 53, 76, 0.05) 0px 7px 25px 0px;
   margin-bottom: 30px;
   background-color: rgb(255, 255, 255);
}
.topBannerWrap .discountInfo span{
   -webkit-box-sizing: border-box;
           box-sizing: border-box;
   margin-bottom: 0px;
   margin-top: 0px;
   font-size: 14px;
   color: rgb(15, 33, 55);
   font-weight: 500;
}
.topBannerWrap .discountInfo span.color-pink{
   -webkit-box-sizing: border-box;
           box-sizing: border-box;
   margin-bottom: 0px;
   margin-right: 0.4em;
   margin-top: 0px;
   font-size: 14px;
   font-weight: 700;
}

.topBannerWrap .headingWrap h1{
   color: rgb(15, 33, 55);
   font-weight: 700;
   letter-spacing: -0.025em;
   margin-bottom: 25px;
   font-size: 55px;
}
.topBannerWrap .headingWrap p{
   -webkit-box-sizing: border-box;
           box-sizing: border-box;
   margin-bottom: 0px;
   margin-top: 0px;
   font-size: 16px;
   color: rgba(52, 61, 72, 0.8);
   line-height: 1.75;
   font-weight: 400;
}
.topBannerWrap .buttonWrap{
   margin-top: 40px;
}
.topBannerWrap .buttonWrap .btnBlue{
   min-width: 156px;
   cursor: pointer;
   display: -ms-inline-flexbox;
   display: inline-flex;
   -ms-flex-align: center;
       align-items: center;
   -ms-flex-pack: center;
       justify-content: center;
   min-height: 46px;
   border-radius: 3px;
   font-family: inherit;
   font-weight: 500;
   text-decoration: none;
   text-transform: uppercase;
   padding: 8px 15px;
   font-size: 14px;
}
.topBannerWrap .buttonWrap .btnTransparent{
   cursor: pointer;
   display: -ms-inline-flexbox;
   display: inline-flex;
   -ms-flex-align: center;
       align-items: center;
   -ms-flex-pack: center;
       justify-content: center;
   min-height: 46px;
   border-radius: 3px;
   font-family: inherit;
   font-weight: 500;
   text-decoration: none;
   text-transform: uppercase;
   padding: 8px 15px;
   font-size: 14px;
   margin-left: 18px;
   min-width: 156px;
   border-color: rgba(82, 104, 219, 0.2);
}
.topBannerWrap .leftSection{
   width: 45%;
}
.topBannerWrap .rightSection{
   position: absolute;
   width: 50%;
   height: 100%;
   top: 0px;
   right: 0px;
   display: -ms-flexbox;
   display: flex;
   -ms-flex-align: center;
       align-items: center;
}
.topBannerWrap .objectWrapper {
    margin-left: auto;
    position: relative;
}
.topBannerWrap .objectWrapper img{
   display: block;
   max-width: 100%;
   height: auto;
   -webkit-box-sizing: border-box;
           box-sizing: border-box;
   margin: 0px;
}
.dashboardWrapper {
    position: absolute;
    top: 0px;
    right: 0px;
}
.ourServicesWrap{
  padding-bottom: 180px;
}
.ourServicesWrap .featureBlock .imgWrapper{
   position: relative;
   background: rgba(0, 0, 0, 0) -webkit-linear-gradient(150deg, rgba(236, 0, 140, 0.75), rgba(255, 103, 103, 0.75)) repeat scroll 0% 0%;
   background: rgba(0, 0, 0, 0) -o-linear-gradient(150deg, rgba(236, 0, 140, 0.75), rgba(255, 103, 103, 0.75)) repeat scroll 0% 0%;
   background: rgba(0, 0, 0, 0) linear-gradient(-60deg, rgba(236, 0, 140, 0.75), rgba(255, 103, 103, 0.75)) repeat scroll 0% 0%;
   border-bottom-left-radius: 6px;
 overflow: hidden;
 margin-bottom: 30px;
 height: 84px;
 width: 84px;
-ms-flex-align: center;
    align-items: center;
-ms-flex-pack: center;
    justify-content: center;
color: rgb(255, 255, 255);
border-radius: 50%;
display: -ms-flexbox;
display: flex;
border-bottom-left-radius: 6px;
}
.ourServicesWrap .featureBlock .imgWrapper:before {
    -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
            transform: rotate(45deg);
    background-color: rgba(255, 255, 255, 0.15);
}
.ourServicesWrap .featureBlock .imgWrapper:after{
   background-color: rgba(0, 0, 0, 0.05);
   -webkit-transform: rotate(-45deg);
       -ms-transform: rotate(-45deg);
           transform: rotate(-45deg);
}
.ourServicesWrap .featureBlock .imgWrapper:after,.ourServicesWrap .featureBlock .imgWrapper:before{
   content: "";
   width: 28px;
   height: calc(100% + 30px);
   position: absolute;
}

.ourServicesWrap .featureBlock .imgWrapper img{
   width: 36px;
}
.ourServicesWrap h2 span,.pricinSecWrap h2 span,.faqWrap h2 span,.freeTrialWrap h2 span{
   margin-bottom: 10px;
   margin-top: 0px;
   font-size: 14px;
   color: rgb(82, 104, 219);
   display: block;
   font-weight: 700;
   text-align: center;
   letter-spacing: 0.15em;
}
.ourServicesWrap h2,.pricinSecWrap h2,.faqWrap h2,.freeTrialWrap h2{
   color: rgb(15, 33, 55);
font-weight: 500;
text-align: center;
letter-spacing: -0.025em;
font-size: 24px;
margin-bottom: 80px;
}
.ourServicesWrap .featureBlock h3{
   margin-bottom: 20px;
   color: rgb(15, 33, 55);
   font-weight: 400;
   line-height: 1.5;
   letter-spacing: -0.02em;
   margin-top: 0px;
   font-size: 20px;
}
.ourServicesWrap .featureBlock p{
    margin-bottom: 1rem;
    margin-top: 0px;
    font-size: 15px;
    color: rgba(52, 61, 76, 0.8);
    line-height: 1.75;
}
.howItWorkWrap .objectWrap{
   margin-right: auto;
   position: relative;
}
.howItWorkWrap .innerleftsec{
   position: absolute;
   width: 55%;
   height: 100%;
   top: 0px;
   left: 0px;
   display: -ms-flexbox;
   display: flex;
   -ms-flex-align: center;
       align-items: center;
   -ms-flex-pack: end;
       justify-content: flex-end;
}
.howItWorkWrap .objectWrap img,.howItWorkWrap .dashboardWrap img{
   max-width: 93%;
  height: auto;
}
.howItWorkWrap .dashboardWrap{
    position: absolute;
    left: 0px;
    top: 4vw;
}
.howItWorkWrap{
   min-height: 630px;
display: -ms-flexbox;
display: flex;
-ms-flex-align: center;
    align-items: center;
margin-bottom: 40px;
position: relative;
}
.howItWorkWrap .innerRightSection{
   margin-left: 58%;
   width: 45%;
}
.howItWorkWrap .innerRightSection h2 {
    max-width: 440px;
    font-size: 36px;
    color: rgb(15, 33, 55);
    font-weight: 400;
    line-height: 1.5;
    letter-spacing: -0.01em;
    margin-top: 0px;
    margin-bottom: 20px;
 }
 .howItWorkWrap .innerRightSection p{
   font-size: 16px;
color: rgba(52, 61, 72, 0.8);
line-height: 1.75;
margin-bottom: 33px;
margin-top: 0px;
max-width: 440px;
 }
  .howItWorkWrap .innerRightSection a{
   cursor: pointer;
   display: -ms-inline-flexbox;
   display: inline-flex;
   -ms-flex-align: center;
       align-items: center;
   -ms-flex-pack: center;
       justify-content: center;
   min-height: 46px;
   border-radius: 3px;
   font-family: inherit;
   font-weight: 500;
   text-decoration: none;
   text-transform: capitalize;
   padding: 8px 22px;
   border: 0px none rgb(82, 104, 219);
   -webkit-transition: all 0.3s ease 0s;
   -o-transition: all 0.3s ease 0s;
   transition: all 0.3s ease 0s;
   background-color: rgb(82, 104, 219);
   background-image: -webkit-linear-gradient(59deg, rgba(215, 178, 233, 0.4) 0%, rgba(83, 105, 220, 0.4) 100%);
   background-image: -o-linear-gradient(59deg, rgba(215, 178, 233, 0.4) 0%, rgba(83, 105, 220, 0.4) 100%);
   background-image: linear-gradient(31deg, rgba(215, 178, 233, 0.4) 0%, rgba(83, 105, 220, 0.4) 100%);
   -webkit-box-sizing: border-box;
           box-sizing: border-box;
   min-width: 156px;
   font-size: 14px;
   color: rgb(255, 255, 255);
   display: inline-block;
   display: -ms-flexbox;
   display: flex;
   max-width: 156px;
   -ms-flex:0 0 156px;
       flex:0 0 156px;
}
.ourServicesWrap.ourFeaturesWrap .featureBlock .imgWrapper::after, .ourServicesWrap.ourFeaturesWrap .featureBlock .imgWrapper::before{
   content: none;
}
.ourServicesWrap.ourFeaturesWrap .featureBlock .imgWrapper{
   border-bottom-right-radius: 6px;
   border-bottom-left-radius: 50%;
}
.ourServicesWrap.ourFeaturesWrap .appDevelopment .imgWrapper{
   background: #fff5f6;
}
.ourServicesWrap.ourFeaturesWrap .TimesAward .imgWrapper{
   background:#ebfff2;
}
.ourServicesWrap.ourFeaturesWrap .cloudStroage .imgWrapper{
   background:#f1faff;
}
.ourServicesWrap.ourFeaturesWrap .clientSatisfaction .imgWrapper{
   background:#fffae8;
}
.ourServicesWrap.ourFeaturesWrap .uxPlanning .imgWrapper{
   background:#f5eeff;
}
.ourServicesWrap.ourFeaturesWrap .customerSupport .imgWrapper{
   background:#ffecfa;
}

.ourServicesWrap.ourFeaturesWrap .featureBlock {
   display: -ms-flexbox;
   display: flex;
   padding: 20px 0px;
}
.ourServicesWrap.ourFeaturesWrap .imgWrapper{
   -ms-flex: 0 0 84px;
       flex: 0 0 84px;
   max-width: 84px;
}
.ourServicesWrap.ourFeaturesWrap .contentWrap{
   padding-left: 20px;
   -ms-flex: 1 1;
       flex: 1 1;
}
.ourServicesWrap.ourFeaturesWrap .featureBlock h3{
   margin-bottom: 15px
}
.pricinSecWrap{
   padding-top: 80px;
   padding-bottom: 80px;
}
.pricinSecWrap .nav-tabs .nav-link{
   font-size: 16px;
   font-weight: 400;
   color: rgb(111, 122, 135);
   background: rgb(255, 255, 255) none repeat scroll 0% 0%;

   height: 50px;
   width: 165px;
   border: 1px solid rgb(228, 233, 238);
   text-align: center;
   display: -ms-inline-flexbox;
   display: inline-flex;
   -ms-flex-pack: center;
       justify-content: center;
   -ms-flex-align: center;
       align-items: center;
   border-radius: 5px;
}
.pricinSecWrap .nav-tabs .nav-item.show .nav-link,.pricinSecWrap .nav-tabs .nav-link.active{
   color: rgb(81, 103, 219);
border-color: rgb(81, 103, 219);
}
.pricinSecWrap .nav-tabs li:first-child .nav-link{

   border-top-right-radius: 0px;
   border-bottom-right-radius: 0px;
}
.pricinSecWrap .nav-tabs li:last-child .nav-link{

   border-top-left-radius: 0px;
   border-bottom-left-radius: 0px;
}
.pricinSecWrap .nav-tabs{
   -ms-flex-pack: center;
       justify-content: center;
   border-bottom:0px;
   margin-bottom: 70px;
}
.pricinSecWrap h2{
   margin-bottom: 30px;
}

.pricingTabWrap .planWrap{
   border: 1px solid rgb(242, 244, 247);
   padding: 60px 45px;
   border-radius: 5px;
   margin-bottom: 30px;
}
.pricingTabWrap .planWrap h2{
   -webkit-box-sizing: border-box;
           box-sizing: border-box;
   margin-bottom: 12px;
   margin-top: 0px;
   font-size: 22px;
   color: rgb(15, 33, 55);
   font-weight: 500;
   text-align: center;
   letter-spacing: -0.025em;
}
.pricingTabWrap .planWrap p{
   margin-bottom: 0px;
   margin-top: 0px;
   font-size: 16px;
   color: rgb(93, 100, 109);
   text-align: center;
   line-height: 1.75;
   margin-bottom: 40px;
}
.pricingTabWrap .planWrap p.price span{
   margin-bottom: 0px;

   margin-top: 0px;

   font-size: 40px;

   color: rgb(15, 33, 55);

   display: block;

   text-align: center;

   letter-spacing: -0.025em;

   display: block;

   padding: 0px;

   line-height: 1.2;
}
.pricingTabWrap .planWrap p.price{
   font-size: 14px;
   margin-bottom: 25px;
}
.pricingTabWrap .planWrap button,.pricingTabWrap .planWrap a{
   cursor: pointer;
display: -ms-inline-flexbox;
display: inline-flex;
-ms-flex-align: center;
    align-items: center;
-ms-flex-pack: center;
    justify-content: center;
min-height: 46px;
min-width: 48px;
border-radius: 3px;
font-family: inherit;
font-weight: 500;
text-decoration: none;
text-transform: capitalize;
padding: 8px 10px;
-webkit-transition: all 0.3s ease 0s;
-o-transition: all 0.3s ease 0s;
transition: all 0.3s ease 0s;
color: rgb(82, 104, 219);
border: 1px solid rgb(82, 104, 219);
    border-top-color: rgb(82, 104, 219);
    border-right-color: rgb(82, 104, 219);
    border-bottom-color: rgb(82, 104, 219);
    border-left-color: rgb(82, 104, 219);
background-color: transparent;
-webkit-box-sizing: border-box;
        box-sizing: border-box;
width: 222px;
max-width: 100%;
font-size: 14px;
}
.pricingTabWrap .planWrap button:hover,.pricingTabWrap .planWrap a:hover{
   color: rgb(255, 255, 255);
background-color: rgb(82, 104, 219);
border-color: transparent;
-webkit-box-shadow: rgba(82, 104, 219, 0.57) 0px 9px 20px -5px;
        box-shadow: rgba(82, 104, 219, 0.57) 0px 9px 20px -5px;
background-image: -webkit-linear-gradient(59deg, rgba(215, 178, 233, 0.4) 0%, rgba(83, 105, 220, 0.4) 100%);
background-image: -o-linear-gradient(59deg, rgba(215, 178, 233, 0.4) 0%, rgba(83, 105, 220, 0.4) 100%);
background-image: linear-gradient(31deg, rgba(215, 178, 233, 0.4) 0%, rgba(83, 105, 220, 0.4) 100%);
}
.pricingTabWrap .planWrap ul li{
   color: rgb(93, 100, 109);
   font-size: 16px;
   font-weight: 400;
   list-style: none;
   margin-bottom: 20px;
   position: relative;
   padding-left: 35px;
}
.pricingTabWrap .planWrap ul{
   margin-top: 40px;
}
.pricingTabWrap .planWrap ul li:before{
   content: '';
   position: absolute;
   left: 0px;
   top: 3px;
   background: url(../images/checked.png);
   height: 19px;
   width: 19px;
   background-size: 100% 100%;
}
.pricingTabWrap .planWrap button.btnBlue,.pricingTabWrap .planWrap a.btnBlue{
   border: 0px none rgb(82, 104, 219);
-webkit-transition: all 0.3s ease 0s;
-o-transition: all 0.3s ease 0s;
transition: all 0.3s ease 0s;
background-color: rgb(82, 104, 219);
background-image: -webkit-linear-gradient(59deg, rgba(215, 178, 233, 0.4) 0%, rgba(83, 105, 220, 0.4) 100%);
background-image: -o-linear-gradient(59deg, rgba(215, 178, 233, 0.4) 0%, rgba(83, 105, 220, 0.4) 100%);
background-image: linear-gradient(31deg, rgba(215, 178, 233, 0.4) 0%, rgba(83, 105, 220, 0.4) 100%);
color: #fff
}

.ourServicesWrap.ourFeaturesWrap{
  padding-top: 150px;
  padding-bottom: 40px;
}
.clientsCommentWrap{
  padding: 80px 0px 120px;
}
.clientImageWrap .imgBgGradientWrap{
    width: 256px;
    height: 256px;
    border-radius: 50% 50% 10px;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    background: rgba(0, 0, 0, 0) -webkit-radial-gradient(center center, circle, rgb(232, 230, 192) 0%, rgb(199, 195, 134) 100%) repeat scroll 0% 0%;
    background: rgba(0, 0, 0, 0) -o-radial-gradient(center center, circle, rgb(232, 230, 192) 0%, rgb(199, 195, 134) 100%) repeat scroll 0% 0%;
    background: rgba(0, 0, 0, 0) radial-gradient(circle at center center, rgb(232, 230, 192) 0%, rgb(199, 195, 134) 100%) repeat scroll 0% 0%;
    overflow: hidden;
}
.clientImageWrap .imgBgGradientWrap img{
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: top center;
     object-position: top center;
}
.clientImageWrap{
  width: 256px;
  height: 285px;
  position: relative;
}
.clientsCommentWrap .clientCommenttext{
  margin: 0px auto 20px;
  padding-left: 0px;
  max-width: 954px;
  font-size: 14px;
  color: rgb(82, 104, 219);
  display: block;
  font-weight: 700;
  letter-spacing: 0.15em;
  margin-left: 0px;
}

.clientCommentsInnerWrap{
  margin-top: 0px;
  font-size: 30px;
  color: rgb(15, 33, 55);
  font-weight: 400;
  line-height: 1.72;
}
.clientsCommentWrap .clientName span{
  margin-bottom: 0px;
  margin-top: 0px;
  font-size: 16px;
  color: rgb(52, 61, 72);
  font-weight: 500;
  line-height: 30px;
}
.clientsCommentWrap .clientName{
  margin-top: 0px;
  font-size: 16px;
  color: rgba(52, 61, 72, 0.8);
  font-weight: 400;
  line-height: 30px;
}
.clientsCommentWrap p img{
  width: 20px;
  position: relative;
  top: -13px;
}

.clientsCommentWrap .socialMediaicon{
  position: absolute;
  z-index: 2;
  bottom: 25px;
  left: 20px;
  width: 65px;
  height: 65px;
  font-size: 26px;
  background-color: rgb(220, 57, 95);
  -webkit-box-shadow: rgba(23, 65, 104, 0.2) 0px 10px 28.2px 1.8px;
          box-shadow: rgba(23, 65, 104, 0.2) 0px 10px 28.2px 1.8px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: center;
      justify-content: center;
  border-radius: 100%;
}
.clientsCommentWrap .socialMediaicon img{
  width: 30px;
}
.clientsCommentWrap .carousel-control-next, .clientsCommentWrap .carousel-control-prev{
  height: 19px;
  bottom: 0px;
  top: auto;
  width: 24px;
}
.clientsCommentWrap .carousel-control-next{
  left: 40px;
  -webkit-transform: rotate(180deg);
      -ms-transform: rotate(180deg);
          transform: rotate(180deg);
}
.clientSliderContentWrap{
  max-width: 950px;
}
.faqWrap{
  background: #fafbff;
  padding: 80px 0px;
}
.faqWraptextWrap .card-header{
  border:none;
border-bottom: 0px solid rgb(235, 235, 235);
background: transparent;
padding: 20px 30px;
}
.faqWraptextWrap .btn.btn-link{
  margin-bottom: 0px;
margin-top: 0px;
font-size: 21px;
color: rgb(15, 33, 55);
font-weight: 400;
letter-spacing: -0.025em;
padding: 0px;
position: relative;
width: 100%;
text-align: left;
padding-right: 20px;
}
.faqWraptextWrap .card-body{
  padding: 20px 30px;
  margin-bottom: 0px;
  margin-top: 0px;
  font-size: 18px;
  color: rgb(93, 100, 109);
  font-weight: 400;
  line-height: 1.75;
  padding-top: 0px;
}
.faqWraptextWrap .btn.btn-link:hover{
  text-decoration: none;
}
.faqWraptextWrap .card{
  border:none;
  border-bottom: 1px solid rgb(235, 235, 235); 
}
.faqWrap .free-trialBtn:hover{
  color: rgb(255, 255, 255);

background-color: rgb(82, 104, 219);

border-color: transparent;

-webkit-box-shadow: rgba(82, 104, 219, 0.57) 0px 9px 20px -5px;

box-shadow: rgba(82, 104, 219, 0.57) 0px 9px 20px -5px;

background-image: -webkit-linear-gradient(59deg, rgba(215, 178, 233, 0.4) 0%, rgba(83, 105, 220, 0.4) 100%);

background-image: -o-linear-gradient(59deg, rgba(215, 178, 233, 0.4) 0%, rgba(83, 105, 220, 0.4) 100%);

background-image: linear-gradient(31deg, rgba(215, 178, 233, 0.4) 0%, rgba(83, 105, 220, 0.4) 100%);
}
.faqWrap .free-trialBtn{
  cursor: pointer;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -ms-flex-align: center;
        align-items: center;
    -ms-flex-pack: center;
        justify-content: center;
    min-height: 46px;
    min-width: 48px;
    border-radius: 3px;
    font-family: inherit;
    font-weight: 500;
    text-decoration: none;
    text-transform: capitalize;
    padding: 8px 22px;
    -webkit-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
    color: rgb(82, 104, 219);
    border: 1px solid rgb(82, 104, 219);
    background-color: transparent;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    height: 48px;
    font-size: 14px;
    min-width: 166px;
    margin-top: 50px;

}
.faqWraptextWrap .card {
  border-bottom: 1px solid rgb(235, 235, 235)!important;
}
.faqWraptextWrap{
  max-width: 820px;
  margin: auto;
}
.faqWraptextWrap .btn.btn-link:after{
  content: '';
  background-image: url('../images/remove.png');
  background-size: 100% 100%;
  position: absolute;
  height: 15px;
  width: 15px;
  top: 6px;
  right: 0px;
}
.faqWraptextWrap .btn.btn-link.collapsed:after{
  content: '';
  background-image: url('../images/add-faq.png');
  background-size: 100% 100%;
  position: absolute;
  height: 15px;
  width: 15px;
  top: 6px;
  right: 0px;
}




  .uploadDocumentEnterDocumentWrap .active{
  display: block;
  }
  .uploadDocumentEnterDocumentWrap .hide{
      display: none;
  }
  .uploadDocumentEnterDocumentWrap .activelink{
    color: red;        
  }
  .container-940{
    max-width: 970px;
    margin: auto;
    padding-left: 15px;
    padding-right: 15px;
  }
  .uploadDocumentEnterDocumentWrap{
    padding:40px 0px;
    padding-top: 0px;
  }
  .uploadDocumentEnterDocumentWrap .tabWrap{
    background: #fff;
    padding: 0px;
    -webkit-box-shadow: 0 0px 8px 0 rgb(235, 235, 235);
            box-shadow: 0 0px 8px 0 rgb(235, 235, 235);
  }
  .uploadDocumentEnterDocumentWrap .tabWrap ul{
    list-style: none;
    padding-left: 0px;
    margin-top:0px;
    margin-bottom: 0px;
  }
  .uploadDocumentEnterDocumentWrap .tabWrap{
    display: -ms-flexbox;
    display: flex;
  }
  .uploadDocumentEnterDocumentWrap .tabNav{
    -ms-flex:0 0 200px;
        flex:0 0 200px;
    max-width: 200px;
    background: #f7f7f7;
  }
  .uploadDocumentEnterDocumentWrap .tabContentWrap{
    -ms-flex: 1 1;
        flex: 1 1;
  }
  .uploadDocumentEnterDocumentWrap .tabNav li a{
    font-family: 'Gilroy', sans-serif;
    font-size: 16px;
    line-height: 16px;
    font-weight: 500;
    color: #444;
    text-decoration: none;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
        align-items: center;
    padding: 13px 20px;
    padding-left: 15px;
    background: #f7f7f7;
    border-left: 4px solid #f7f7f7;
    border-bottom:1px solid rgb(235, 235, 235);
    border-right: 1px solid rgb(235, 235, 235);
  }

   .uploadDocumentEnterDocumentWrap .tabNav li a.activelink,.uploadDocumentEnterDocumentWrap .tabNav li a:hover{
       background:#fff;
       border-left: 4px solid rgb(82, 104, 219);
   }
   .uploadDocumentEnterDocumentWrap .tabNav li a.activelink{
       border-right: 0px;
   }
   
   
  .uploadDocumentEnterDocumentWrap .activelink i{
    color: rgb(82, 104, 219);
  }
  .uploadDocumentEnterDocumentWrap .tabNav li a i{
    max-width: 60px;
    -ms-flex: 0 0 60px;
        flex: 0 0 60px;
    font-size: 36px;
  }
  .uploadDocumentEnterDocumentWrap .list{
    padding: 23px;
  }
  /*custom browse button*/
    .upload-btn-wrapper {
      position: relative;
      overflow: hidden;
      display: inline-block;
    }

    .upload-btn-wrapper .btn {
      border: 2px dashed rgb(235, 235, 235);
      color: gray;
      background-color: white;
      padding: 25px;
      border-radius: 8px;
      font-size: 20px;              
       font-weight: 400;
       font-family: 'Gilroy', sans-serif;
    }
    .upload-btn-wrapper .btn .bold{
       font-weight: 700;
    }
    .upload-btn-wrapper .btn p{
       margin-bottom: 15px;
        margin-top: 0px;
        font-size: 16px;
        color: rgba(52, 61, 72, 0.8);
        line-height: 1.75;
        font-weight: 400;
    }
    .upload-btn-wrapper .btn h2{
       font-size: 24px;
       margin: 0 0 5px;
       color: #444;
       line-height: 27px;
       font-weight: 400;
       font-family: 'Gilroy', sans-serif;
    }
    .upload-btn-wrapper input[type=file] {
      font-size: 100px;
      position: absolute;
      left: 0;
      top: 0;
      opacity: 0;
      height: 100%;
      cursor: pointer;
    }
    .browseForDocumentBtn{
       padding: 10px 20px;
       color: #fff;
       position: relative;
       vertical-align: middle;
       line-height: 20px;
       text-align: center;
       font-size: 14px;
       font-weight: 700;
       white-space: nowrap;
       -webkit-transition: border .15s;
       -o-transition: border .15s;
       transition: border .15s;
       border-style: solid;
       border-width: 1px;
       border-radius: 3px;
       width: auto;
       margin:auto;
      border: 0px none rgb(82, 104, 219);
      background-color: rgb(82, 104, 219);
      background-image: -webkit-linear-gradient(59deg, rgba(215, 178, 233, 0.4) 0%, rgba(83, 105, 220, 0.4) 100%);
      background-image: -o-linear-gradient(59deg, rgba(215, 178, 233, 0.4) 0%, rgba(83, 105, 220, 0.4) 100%);
      background-image: linear-gradient(31deg, rgba(215, 178, 233, 0.4) 0%, rgba(83, 105, 220, 0.4) 100%);
      -webkit-transition: all 0.3s ease 0s;
      -o-transition: all 0.3s ease 0s;
      transition: all 0.3s ease 0s;
      color: rgb(255, 255, 255);       
       display: inline-block;
       cursor: pointer;
    }
    .dragDropDocumentWrap:hover .browseForDocumentBtn{
      background-color: rgb(82, 104, 219);
      border-color: rgb(82, 104, 219);
      -webkit-box-shadow: rgba(82, 104, 219, 0.57) 0px 9px 20px -5px;
              box-shadow: rgba(82, 104, 219, 0.57) 0px 9px 20px -5px;
    }
    .tabContentWrap .clickme2 .activelink,.tabContentWrap .clickme2 a:hover{
       border-color: rgb(82, 104, 219) !important;
      
    }
    .tabContentWrap .clickme2 a{
       height: 56px;
       width: 86px;
       background: #fff;
       display: block;
       -webkit-transition: border .2s;
       -o-transition: border .2s;
       transition: border .2s;
       border: 1px solid #fff !important;
       cursor: pointer;
       text-align: center;
       outline: none;
       border-radius: 5px;
    }
     .typeOFFileWrap{
       display: -ms-flexbox;
       display: flex;
       -ms-flex-pack: center;
           justify-content: center;
       padding-top: 30px;
    }
    .typeOFFileWrap li a{
       display: -ms-inline-flexbox!important;
       display: inline-flex!important;
       -ms-flex-pack: center;
           justify-content: center;
       -ms-flex-align: center;
           align-items: center;
    }
    .typeOFFileWrap li{
       margin-left: 5px;
       margin-right: 5px;
    }
    .urlDocumentWrap h2{
       font-size: 24px;
       margin: 0 0 5px;
       color: #444;
       line-height: 27px;
       font-weight: 400;
       font-family: 'Gilroy', sans-serif;
       text-align: center;
    }
    .urlDocumentWrap p{
       color: #666;
       font-size: 15px;
       line-height: 20px;
       font-family: 'Gilroy', sans-serif;
       font-weight: 400;
       text-align: center;
        margin-bottom: 15px;
        margin-top: 0px;
        font-size: 16px;
        color: rgba(52, 61, 72, 0.8);
        line-height: 1.75;
        font-weight: 400;
    }
    .urlDocumentWrap p .bold{
       font-weight: 700;
    }
    .fillNowanchorWrap input{
       border:1px solid rgb(235, 235, 235);
       border-radius: 3px;
       position: relative;
       padding: 10px;
       line-height: 20px;
       color: #444;
       font-size: 14px;
       background: #fff;
       -webkit-transition: border-color .15s ease-in-out;
       -o-transition: border-color .15s ease-in-out;
       transition: border-color .15s ease-in-out;
       -webkit-box-shadow: inset 0px 0px 0px 0 rgba(0,1,1,.07);
       box-shadow: inset 0px 0px 0px 0 rgba(0,1,1,.07);
    }
    .fillNowanchorWrap button{       
       padding: 10px;
       color: #fff;
       position: relative;
       vertical-align: middle;
       line-height: 20px;
       text-align: center;
       font-size: 14px;
       font-weight: 700;
       white-space: nowrap;
       -webkit-transition: border .15s;
       -o-transition: border .15s;
       transition: border .15s;
       border-style: solid;
       border-width: 1px;
       border-radius: 3px;
       border: 0px none rgb(82, 104, 219);
      background-color: rgb(82, 104, 219);
      background-image: -webkit-linear-gradient(59deg, rgba(215, 178, 233, 0.4) 0%, rgba(83, 105, 220, 0.4) 100%);
      background-image: -o-linear-gradient(59deg, rgba(215, 178, 233, 0.4) 0%, rgba(83, 105, 220, 0.4) 100%);
      background-image: linear-gradient(31deg, rgba(215, 178, 233, 0.4) 0%, rgba(83, 105, 220, 0.4) 100%);
      -webkit-transition: all 0.3s ease 0s;
      -o-transition: all 0.3s ease 0s;
      transition: all 0.3s ease 0s;
      color: rgb(255, 255, 255);
      display: inline-block;
      cursor: pointer;

    }
    .fillNowanchorWrap button:hover{
      background-color: rgb(82, 104, 219);
      border-color: rgb(82, 104, 219);
      -webkit-box-shadow: rgba(82, 104, 219, 0.57) 0px 9px 20px -5px;
              box-shadow: rgba(82, 104, 219, 0.57) 0px 9px 20px -5px;
    }
    .fillNowanchorWrap{
       display: -ms-flexbox;
       display: flex;
    }
    .fillNowanchorWrap input{
       -ms-flex:1 1;
           flex:1 1;
       margin-right: 10px;
    }
    .fillNowanchorWrap button{
       -ms-flex: 0 0 152px;
           flex: 0 0 152px;
       max-width: 152px;
       cursor: pointer;
    }
    .fillNowanchorWrap input::-webkit-input-placeholder { /* Chrome/Opera/Safari */
      color: #888;
      opacity: 1;
    }
    .fillNowanchorWrap input::-moz-placeholder { /* Firefox 19+ */
      color: #888;
      opacity: 1;
    }
    .fillNowanchorWrap input:-ms-input-placeholder { /* IE 10+ */
      color: #888;
      opacity: 1;
    }
    .fillNowanchorWrap input:-moz-placeholder { /* Firefox 18- */
      color: #888;
      opacity: 1;
    }
  /*end*/
  .urlDocumentWrap {
    background:url ('../images/host.jpg') 10px -9px no-repeat;
    text-align: center;            
    background-size: 350px;
    min-height: 329px;
  }
  .urlDocumentWrap.active{
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
        flex-direction: column;
    -ms-flex-pack: center;
        justify-content: center;
    min-height: 283px;
  }
  .processingWrap{
    border: 1px solid #ddd;
    padding:20px;
    border-radius: 5px;
  }
  .processingWrap h2{
    font-size: 24px;
    margin: 0 0 5px;
    color: #444;
    line-height: 27px;
    font-weight: 400;
    font-family: 'Gilroy', sans-serif;
    text-align: center;
  }
  .processingWrap ul{
    padding: 0px;
  }
  .processingWrap li{
    color: #666;
    font-size: 15px;
    line-height: 20px;
    font-weight: 400;            
    font-family: 'Gilroy', sans-serif;
    margin-bottom: 12px;
    list-style: none;
  }
  .progress {
     height: 12px;
     margin-bottom: 20px;
     overflow: hidden;
     background-color: #f5f5f5;
     border-radius: 10px;
     -webkit-box-shadow: inset 0 1px 2px rgba(0,0,0,.1);
     box-shadow: inset 0 1px 2px rgba(0,0,0,.1);
     margin-top: 40px;
     margin-bottom: 40px;
  }
  .progress-bar {
     float: left;
     width: 0%;
     height: 100%;
     font-size: 12px;
     line-height: 20px;
     color: #fff;
     text-align: center;
     background-color: #ff9000;
     -webkit-box-shadow: inset 0 -1px 0 rgba(0,0,0,.15);
     box-shadow: inset 0 -1px 0 rgba(0,0,0,.15);
     -webkit-transition: width .6s ease;
     -o-transition: width .6s ease;
     transition: width .6s ease;
  }
  .progress-bar .sr-only {
     position: absolute;
     width: 1px;
     height: 1px;
     padding: 0;
     margin: -1px;
     overflow: hidden;
     clip: rect(0,0,0,0);
     border: 0;
  }
  .processingWrap li:before{
    content: '\f00c';
    font-size: 16px;
    position: absolute;
    left: 0px;
    font-family: fontawesome;
    color: #71b52c;
    
  }
  .processingWrap li{
    position: relative;
    padding-left: 30px;
  }
  .processingWrap li.onprogress:before{
    content: '\f2f1';
    -webkit-animation: rotation 2s infinite linear;
    color: #ff9000
  }
  #file-upload-filename{
    display: none;
  }
  @-webkit-keyframes rotation {
    from {
       -webkit-transform: rotate(0deg);
    }
    to {
       -webkit-transform: rotate(359deg);
    }
  }

  .freeTrialWrap{
    padding-bottom: 80px;
  }

  .whanttoKnowWhenLaunch{
    padding: 80px 0px;
    padding-bottom: 40px;
    background: #fafbff;
  }
  .whanttoKnowWhenLaunch h2{
    font-size: 36px;
    color: rgb(15, 33, 55);
    font-weight: 400;
    line-height: 1.5;
    letter-spacing: -0.01em;
    margin-top: 0px;
  }
  .whanttoKnowWhenLaunch p{
    font-size: 16px;
    color: rgba(52, 61, 72, 0.8);
    line-height: 1.75;
    margin-bottom: 33px;
    margin-top: 0px;
  }
  .subscribeBtn{
    border: 0px none rgb(82, 104, 219);
    -webkit-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
    background-color: rgb(82, 104, 219);
    background-image: -webkit-linear-gradient(59deg, rgba(215, 178, 233, 0.4) 0%, rgba(83, 105, 220, 0.4) 100%);
    background-image: -o-linear-gradient(59deg, rgba(215, 178, 233, 0.4) 0%, rgba(83, 105, 220, 0.4) 100%);
    background-image: linear-gradient(31deg, rgba(215, 178, 233, 0.4) 0%, rgba(83, 105, 220, 0.4) 100%);
    color: #fff;
    cursor: pointer;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -ms-flex-align: center;
        align-items: center;
    -ms-flex-pack: center;
        justify-content: center;
    min-height: 46px;
    min-width: 48px;
    border-radius: 3px;
    font-family: inherit;
    font-weight: 500;
    text-decoration: none;
    text-transform: capitalize;
    padding: 8px 10px;
    -ms-flex: 0 0 150px;
        flex: 0 0 150px;
    max-width: 150px;
  }
  .subscribeBtn:hover{
    color: rgb(255, 255, 255);
    background-color: rgb(82, 104, 219);
    border-color: transparent;
    -webkit-box-shadow: rgba(82, 104, 219, 0.57) 0px 9px 20px -5px;
            box-shadow: rgba(82, 104, 219, 0.57) 0px 9px 20px -5px;
    background-image: -webkit-linear-gradient(59deg, rgba(215, 178, 233, 0.4) 0%, rgba(83, 105, 220, 0.4) 100%);
    background-image: -o-linear-gradient(59deg, rgba(215, 178, 233, 0.4) 0%, rgba(83, 105, 220, 0.4) 100%);
    background-image: linear-gradient(31deg, rgba(215, 178, 233, 0.4) 0%, rgba(83, 105, 220, 0.4) 100%);
  }
  .subscribeInputWrap > div{
    -ms-flex: 1 1;
        flex: 1 1;
    padding-right: 10px;
  }
  .subscribeInputWrap input{
    border-radius: 3px;
    height: 46px;
    border:1px solid rgb(235, 235, 235) !important;
  }
  .subscribeInputWrap input:hover,.subscribeInputWrap input:focus{
    -webkit-box-shadow: 0px 0px 0px 0px;
            box-shadow: 0px 0px 0px 0px;
    border:1px solid rgb(235, 235, 235) !important;
    border-radius: 3px;
  }
  .subscribeInputWrap{
    display: -ms-flexbox;
    display: flex;
    padding-top: 13px;
  }

  footer{
    background-repeat: no-repeat;
    background-position: center 0px;
    padding-top: 30px;
    padding-bottom: 30px;
    position: relative;
    background-color: #f1f1f5;
  }
  footer img{
    max-width: 300px;
  }

  header .navbar-light .navbar-nav .nav-link{
    cursor: pointer;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -ms-flex-align: center;
        align-items: center;
    -ms-flex-pack: center;
        justify-content: center;
    min-height: 46px;
    min-width: 150px !important;
    border-radius: 3px;
    font-family: inherit;
    font-weight: 500;
    text-decoration: none;
    text-transform: capitalize;
    padding: 8px 10px;
    -webkit-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
    color: rgb(82, 104, 219);
    border: 1px solid rgb(82, 104, 219); 
    width: 150px;
    max-width: 100%;
    font-size: 14px;
    text-transform: uppercase;
  }
   header .navbar-light .navbar-nav .nav-link:hover,header .navbar-light .navbar-nav .nav-link:focus{
    color: rgb(255, 255, 255);
    background-color: rgb(82, 104, 219);
    border-color: transparent;
    -webkit-box-shadow: rgba(82, 104, 219, 0.57) 0px 9px 20px -5px;
            box-shadow: rgba(82, 104, 219, 0.57) 0px 9px 20px -5px;
    background-image: -webkit-linear-gradient(59deg, rgba(215, 178, 233, 0.4) 0%, rgba(83, 105, 220, 0.4) 100%);
    background-image: -o-linear-gradient(59deg, rgba(215, 178, 233, 0.4) 0%, rgba(83, 105, 220, 0.4) 100%);
    background-image: linear-gradient(31deg, rgba(215, 178, 233, 0.4) 0%, rgba(83, 105, 220, 0.4) 100%);
  }
  header .navbar-expand-lg .navbar-nav{
    margin-left: auto;
    list-style: none;
  }
  header .navbar-light .navbar-nav .nav-item:last-child{
    padding-right: 0px;
  }
 header .navbar-toggler span{
  display: block;
  width: 100%;
  height: 2px;
  margin: 4px 0px;
  margin-top: 4px;
  float: right;
  background-color: rgb(235, 77, 75);
  -webkit-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
 }
  header .navbar-toggler span:last-child{
    width: calc(100% - 10px);
    margin-bottom: 0px;
  }
  header .navbar-toggler:hover span:last-child{
    width: 100%;
  }
  header .navbar-toggler{
    border: 0px none;
    background: transparent none repeat scroll 0% 0%;
    width: 50px;
    height: 30px;
    cursor: pointer;
  }
  header .navbar-toggler.collapsed span:first-child{
    -webkit-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
            transform: rotate(0deg);
    -webkit-transform-origin: 0px 0% 0px;
        -ms-transform-origin: 0px 0% 0px;
            transform-origin: 0px 0% 0px;
  }
  header .navbar-toggler span:first-child{
    -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
            transform: rotate(45deg);
    -webkit-transform-origin: 11px 50% 0px;
        -ms-transform-origin: 11px 50% 0px;
            transform-origin: 11px 50% 0px;
  }
  header .navbar-toggler.collapsed span:last-child{
   
    -webkit-transform: rotate(0deg);
   
        -ms-transform: rotate(0deg);
   
            transform: rotate(0deg);
    -webkit-transform-origin: 0px 0% 0px;
        -ms-transform-origin: 0px 0% 0px;
            transform-origin: 0px 0% 0px;
  }
  header .navbar-toggler span:last-child{
    width: 100%;
    -webkit-transform: rotate(-45deg);
        -ms-transform: rotate(-45deg);
            transform: rotate(-45deg);
    -webkit-transform-origin: 0px 50% 0px;
        -ms-transform-origin: 0px 50% 0px;
            transform-origin: 0px 50% 0px;
  }
  header .navbar-toggler.collapsed span:nth-child(2){
    display:block;
  }
  header .navbar-toggler span:nth-child(2){
    display:none;
  }
  header .navbar-collapse.show{
    position: absolute;
    background: #fff;
    width: 100%;
    left: 0px;
    right: 0px;
    padding-bottom: 35px;
    padding-left: 15px;
    padding-right: 15px;
    top: 59px;
    -webkit-box-shadow: 0px 4px 7px 0px rgba(0,0,0,.2);
            box-shadow: 0px 4px 7px 0px rgba(0,0,0,.2);
  }

  .loginModalWrap.modal-dialog{
    max-width: 80%;
    margin-left: auto;
    margin-right: auto;
  }
  .loginModalWrap .modal-content{
    overflow-x: hidden;
  }
  .loginRegisterFormWrap .logoImg{
    width: 128px;
  }
  .loginRegisterFormWrap .nav li{
    margin-right: 0px;
    text-align: center;
    max-width: 50%;
    -ms-flex: 0 0 50%;
        flex: 0 0 50%;
  }
  .loginRegisterFormWrap .nav li a.active{
      color: #108ee9;
  }
  .loginRegisterFormWrap .nav li a{
    font-size: 14px;
    line-height: 1.5;
    white-space: nowrap;
    color: #20201d;
    font-weight: 500;    
    padding: 13px 0px;
    display: block;
    border-bottom: 2px solid #f3f3f3;
  }
  .loginRegisterFormWrap .nav li a.active{
    border-bottom: 2px solid #108ee9;
  }
  .loginRegisterFormWrap{
    max-width: 400px;
    padding: 46px
  }
 .loginRegisterFormWrap  h2{
    margin-bottom: 10px;
    margin-top: 35px;
    font-size: 50px;
    color: rgb(32, 32, 29);
    font-weight: 400;
    letter-spacing: -0.025em;
  }
  .loginRegisterFormWrap p{
    margin-bottom: 23px;
    margin-left: 1px;
    margin-top: 0px;
    font-size: 15px;
    color: rgba(52, 61, 72, 0.8);
    line-height: 26px;
    letter-spacing: -0.025em;
  }
  .loginRegisterFormWrap .nav{
      margin-top: 30px;
  }
  .loginRegisterFormWrap .loginWithGoogleBtn{
    font-size: 15px;
    font-weight: 700;
    margin-bottom: 45px;
    -webkit-box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 15px;
            box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 15px;
    display: block;
    text-align: center;
    color: #20201d;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: center;
        justify-content: center;
    -ms-flex-align: center;
        align-items: center;
    padding: 13px;
    text-decoration: none;
  }
  .loginRegisterFormWrap .loginWithGoogleBtn img{
      height: 22px;
      margin-right: 23px;
  }
  .loginForgetWrap a{
    cursor: pointer;
    font-family: inherit;
    font-weight: 700;
    text-transform: capitalize;
    font-size: 14px;
    color: rgb(16, 172, 132);
    margin-left: 30px;
  }
   .loginForgetWrap button{
      background-color: rgb(16, 172, 132);
      -webkit-transition: all 0.3s ease 0s;
      -o-transition: all 0.3s ease 0s;
      transition: all 0.3s ease 0s;
      cursor: pointer;
      display: -ms-inline-flexbox;
      display: inline-flex;
      -ms-flex-align: center;
          align-items: center;
      -ms-flex-pack: center;
          justify-content: center;
      color: rgb(255, 255, 255);
      background-color: rgb(16, 172, 132);
      min-height: 48px;
      border-radius: 3px;
      font-family: inherit;
      font-weight: 700;
      text-decoration: none;
      text-transform: capitalize;
      padding: 10px 20px;
      border: 0px none;
      transition: all 0.3s ease 0s;
      -webkit-box-sizing: border-box;
              box-sizing: border-box;
      min-width: 156px;
      font-size: 14px;
   }
   .loginModalWrap  .modal-body{
    padding: 0px;
   }
   .loginModalWrap .formWrap input{
    font-size: 16px;

padding: 11px;

    padding-right: 11px;
    padding-left: 11px;

padding-right: 11px;

padding-left: 11px;

display: block;

width: 100%;

color: rgba(52, 61, 72, 0.8);

-webkit-box-shadow: none;

        box-shadow: none;

border-radius: 4px;

-webkit-box-sizing: border-box;

        box-sizing: border-box;

border: 1px solid rgb(235, 235, 235);

border-top-color: rgb(235, 235, 235);

border-top-style: solid;

border-top-width: 1px;

border-right-color: rgb(235, 235, 235);

border-right-style: solid;

border-right-width: 1px;

border-left-color: rgb(235, 235, 235);

border-left-style: solid;

border-left-width: 1px;

-webkit-transition: border-color 0.2s ease 0s;

-o-transition: border-color 0.2s ease 0s;

transition: border-color 0.2s ease 0s;

border: navajowhite;

    border-bottom-color: navajowhite;
    border-bottom-style: none;
    border-bottom-width: medium;

border-bottom: 1px solid #ebebeb;
border-radius: 0px;
   }
.loginModalWrap .formWrap .form-group{
  margin-bottom: 30px;
}

/*custom checkbox*/
/* Base for label styling */
.cust-checkboxes [type="checkbox"]:not(:checked),
.cust-checkboxes [type="checkbox"]:checked {
  position: absolute;
  left: -9999px;
}
.cust-checkboxes [type="checkbox"]:not(:checked) + label,
.cust-checkboxes [type="checkbox"]:checked + label {
  position: relative;
  padding-left: 1.95em;
  cursor: pointer;
  color: rgba(52, 61, 72, 0.8);
  margin-bottom: 30px;
}

/* checkbox aspect */
.cust-checkboxes [type="checkbox"]:not(:checked) + label:before,
.cust-checkboxes [type="checkbox"]:checked + label:before {
  content: '';
  position: absolute;
  left: 0; top: 0;
  width: 20px; 
  height: 20px;
  border: 1px solid #dadada;
  background: #fff;
  border-radius: 4px;
}
/* checked mark aspect */
.cust-checkboxes [type="checkbox"]:not(:checked) + label:after,
.cust-checkboxes [type="checkbox"]:checked + label:after {
  content: '\2713\0020';
  position: absolute;
  top: 4px;
  left: 4px;
  font-size: 15px;
  line-height: 0.8;
  color: #09ad7e;
  -webkit-transition: all .2s;
  -o-transition: all .2s;
  transition: all .2s;
 
}
/* checked mark aspect changes */
.cust-checkboxes [type="checkbox"]:not(:checked) + label:after {
  opacity: 0;
  -webkit-transform: scale(0);
      -ms-transform: scale(0);
          transform: scale(0);
}
.cust-checkboxes [type="checkbox"]:checked + label:after {
  opacity: 1;
  -webkit-transform: scale(1);
      -ms-transform: scale(1);
          transform: scale(1);
}
/* disabled checkbox */
.cust-checkboxes [type="checkbox"]:disabled:not(:checked) + label:before,
.cust-checkboxes [type="checkbox"]:disabled:checked + label:before {
  -webkit-box-shadow: none;
          box-shadow: none;
  border-color: #bbb;
  background-color: #ddd;
}
.cust-checkboxes [type="checkbox"]:disabled:checked + label:after {
  color: #999;
}
.cust-checkboxes [type="checkbox"]:disabled + label {
  color: #aaa;
}

/*end*/

/*.loginModalWrap  .close{
  position: absolute;
  right: -10%;
  color: #fff;
  opacity: 1;
  font-size: 38px;
  top: -3%;
}*/
.loginModalWrap .formWrap{
  min-height: 252px;
}
.modal-backdrop.show{
  opacity: .8
}
.login-img {
  -o-object-fit: cover;
     object-fit: cover; 
  height: 100%
}

.modal-dialog {
    min-height: calc(100vh - 60px);
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
        flex-direction: column;
    -ms-flex-pack: center;
        justify-content: center;
    overflow: auto;
  }

/* new css style  */
.bodywrapper{}
.wrapperwhite{}
.freetrailwrapper{
    margin: 50px 0px;
}
.freetrailhead{
    text-align: center;
    padding: 30px 0px;
}
.frtxt{
    color: #0041FF;
    font-size: 20px;
    font-weight: 700;
}
.trytxt{
    color: #00145a;
    font-size: 26px;
    padding: 10px 0px;
    font-weight: 700;
}
.docuploadbody{
    box-shadow: 0.5px 0.5px 3.5px 0.5px #ccc;
    padding-bottom: 10px;
}
.docuploadbody ul{
    list-style: none;
    padding-left: 0px;
}
.docuploadbody ul li{
    display: inline-block;
    padding: 10px 15px;
    font-size: 16px;
    cursor: pointer;
}
.docuploadbody ul li.fileactive{
    color: #0444ff;
    border-bottom: solid 2px #0444ff;
}
.upload_docwrapper{
    padding: 15px;
    text-align: center;
    border: dashed 1px #ccc;
    margin: 20px;
}
.upload_docwrapper p{
    color: #616d98;
    margin: auto;
    font-size: 20px;
    width: 80%;
}
.upload_docwrapper h2{
    color: #4c5a8b;
    font-size: 25px;
    font-weight: 800;
}
.urltxt{
    width: 300px;
    height: 42px;
    padding: 6px;
    display: inline-block;
    vertical-align: middle;
}
.brnsub{
    background: #0041ff;
    color: #fff;
    border: none;
    padding: 7px 50px;
    display: inline-block;
    vertical-align: middle;
    font-size: 20px;
}
.btncontainer {
  margin-top: 20px;
}
.fratureWrapper{
    overflow: hidden;
    margin: 50px 0px;
}
.fratureitem{
    float: left;
    width: 33.33%;
    padding: 15px;
    text-align: center;
}
.itemimgcontainer{
  width: 60%;
  margin: auto;
}
.itemimgcontainer img{}
.itemtitle{
    color: #00145a;
    font-size: 20px;
    font-weight: 700;
    padding: 20px 0px;
}
.itemtxt{
    color: #0e2063;
    font-size: 15px;
    width: 64%;
    font-weight: 600;
    margin: auto;
}
.pricewrapper{
    margin: 50px 0px;
}
.planwrapper{
    overflow: hidden;
    width: 60%;
    margin: auto;
}
.plancontant{
    text-align: center;
    margin: auto;
    box-shadow: 0.5px 0.5px 2.5px .5px #ccc;
    padding: 30px 20px;
}
.plan_name{
    color: #00145a;
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 20px;
}
.planuse{}
.planuse{
    color: #04185d;
    font-weight: 600;
    padding-bottom: 15px;
    font-size: 15px;
}
.cricons{}
.permonth{
    font-weight: 400;
    font-size: 12px;
}
.starbtn{
    width: 60%;
    margin: 15px auto;
    background: #0041ff;
    color: #fff;
    padding: 10px;
    border-radius: 4px;
    cursor: pointer;
}
.planfeature{}
.planfeature ul{
    list-style: none;
    padding-left: 0;
}
.planfeature ul li {
  color: #04185d;
  font-weight: 600;
  padding: 5px 0px;
}
.testtimonialwrapper{
    margin: 50px 0px;
    display: none;
}
.planoutercontant{
    width: 50%;
    float: left;
    padding: 10px;
}
.planprice {
  color: #04185d;
  font-weight: 800;
  font-size: 20px;
  line-height: 20px;
  padding-bottom: 15px;
}
span.checkicons {
  color: #18d37a;
  display: inline-block;
  padding-right: 15px;
}
.testxt{
    color: #056dff;
    font-weight: 600;
    font-size: 20px;
}
.testimonialcontant{}
.testimonialitem{}
.testleft{
    float: left;
    width: 75%;
    position: relative;
    padding-left: 20px;
    padding-top: 10px;
}
.blockquote{
    font-size: 30px;
    color: #d6d7d9;
    position: absolute;
    left: -10px;
    top: 2px;
}
.bloTxt{
    color: #0f2137;
    font-size: 43px;
    font-weight: 500;
}
.testfooetr{}
.name{
    color: #37404b;
    font-weight: 800;
    font-size: 16px;
}
.deginatio{
    color: #6d737b;
    font-size: 16px;
    font-weight: 600;
    padding-left: 15px;
}
.testright{
    float: right;
    width: 20%;
}
.bottomfotter{
    margin: 50px 0px;
    clear: both;
    background: #f0f1f5;
    padding: 40px 0px;
    display: none;
}
.faqcontant h2 {
  color: #0f2264;
  font-size: 25px;
  cursor: pointer;
  font-weight: 500;
}
span.plusicons {
  float: right;
  font-size: 30px;
  -webkit-text-stroke: 3px #f0f1f5;
}
.cushide {
  display: none;
}
.faqcontant {
  padding: 10px 0px;
  border-bottom: solid 1px #d6d7da;
}
.faqfree {text-align: center;padding: 50px 0px;}
.trialbtn {display: inline-block;background: #0041ff;color: #fff;padding: 10px 30px;font-size: 16px;border-radius: 4px;}
.bottomfotter img{}

.lunchwrapper{}
.leftlunch{
    display: inline-block;
    vertical-align: middle;
    width: 58%;
}
.launchtxt{
    color: #00145a;
    font-size: 25px;
    font-weight: 700;
}
.subtxt{
    color: #00145a;
    font-size: 15px;
}
.subtextbox{
    display: inline-block;
    width: 68%;
    padding-right: 10px;
}
.rightlunch{
    display: inline-block;
    vertical-align: middle;
    width: 40%;
    text-align: right;
}
.subbtn{
    display: inline-block;
    width: 30%;
}
input.subscriptionTxt {
  height: 41px;
  padding: 4px 15px;
  margin-right: 5px;
  width: 100%;
}
.footerwrapper{}
.footerwrapper img{
    width: 200px;
    margin: auto;
}

.testimonialcontant .owl-next {
  display: inline-block;
  padding-left: 17px;
  color: #8f9092;
  font-size: 17px;
}
.testimonialcontant .owl-prev {
  display: inline-block;
  font-size: 17px;
  color: #8f9092;
}

/* end of new css style */
@media(max-width: 768px) {
  .modal-dialog {
    min-height: calc(100vh - 20px);
  }

  /* new media for mobile */
  .topBannerWrap .leftSection {
    width: 100%;
}
.topBannerWrap .rightSection {
  position: relative;
  width: 100%;
}
.urltxt {
  width: 214px;
  height: 42px;
}
.fratureitem {
  float: left;
  width: 100%;
  padding: 15px;
  text-align: center;
}
.itemimgcontainer img {
  margin: auto;
}
.itemtxt {
  margin: auto;
}
.planoutercontant{
  width: 100%;
}
.bloTxt{font-size:15px;}
.rightlunch {
  display: block;
  vertical-align: middle;
  width: 100%;
  margin: auto;
}
.leftlunch {
  display: inline-block;
  vertical-align: middle;
  width: 100%;
  text-align: center;
}
.topBannerWrap .headingWrap h1{
  font-size: 21px;
}
.trytxt{font-size: 22px}
.docuploadbody ul li {
  display: inline-block;
  padding: 10px 10px;
  font-size: 13px;
  cursor: pointer;
  font-weight: 600;
}
.upload_docwrapper h2 {
  color: #4c5a8b;
  font-size: 21px;
  font-weight: 800;
  line-height: 25px;
}
.upload_docwrapper p {
  color: #616d98;
  margin: auto;
  font-size: 15px;
  width: 100%;
  padding: 10px 0px;
}
.planwrapper{
  width: 100%;
}
.blockquote {
  font-size: 20px;
  color: #d6d7d9;
  position: absolute;
  left: -7px;
  top: 2px;
}
.deginatio {
  color: #6d737b;
  font-size: 16px;
  font-weight: 600;
  padding-left: 0px;
  display: block;
}
.faqcontant h2{
  font-size: 17px;
}
span.plusicons{
  font-size: 25px;
  margin-top: -2px;
}
.subtextbox {
  display: block;
  width: 84%;
  padding-right: 10px;
  text-align: left;
  margin: auto;
}
.trialbtn {
  display: block;
  width: 43%;
  margin: 10px auto;
  text-align: center;
}
.mar-lf-auto{
  float: left;
}
header .navbar-light .navbar-nav .nav-item {
  padding-right: 11px;
  float: left;
  margin-bottom: 10px;
  border-bottom: solid 1px #ccc;
}
  /* end of media for mobile */
}
.login-error-msg {
  color: red;
}
.faqwrapper{
  display: none;
}

