$white: #fff;
.margin-top50 {
  margin-top: 50px;
  margin-left: 30px;
}
.credit {
  margin-top: 100px;
  height: 250px;
  width: 400px;
  border: 0;
  border-radius: 10px;
  background: url('../../../assets/images/debitCard.jpg') no-repeat center center;
  background-size: cover;
  color: $white;
  &__bank {
    font-size: 24px;
    font-weight: bold;
  }
  &__mc {
    width: 80px;
    float: right;
  }
  &__card-number {
    height: 91px;
    padding-top: 40px;
    font-weight: 600;
    font-size: 32px;
    letter-spacing: 2px;
  }
  &__ccv {
    height: 24px;
    float: right;
    margin-top: -10px;
    text-transform: uppercase;
  }
  &__name,
  &__date{
    text-transform: uppercase;
    font-weight: 600;
    font-size: 16px;
    height: 25px;
  }
  label {
    color: $white;
  }
}
.customeform {
  width: 50%;
  margin: auto;
  padding-top: 100px;
}
.paymentwrapper{
  width: 534px;
  margin: auto;
  padding: 10px;
}
.customeform .form-group {
  margin-bottom: 1rem;
  display: inline-block;
  width: 49%;
  text-align: left;
  padding: 0px 15px;
}
.customeform select {
  background-color: #fff;
}
.customeform label{display:block;}
.customeform input, .customeform button, .customeform select, .customeform optgroup, .customeform textarea{width:100%; padding-left: 15px;}
.StripeElement.StripeElement--empty {
  padding: 40px 0px;
}
.customepaybtn{
  display: inline-block;
    width: 50% !important;
    margin: auto;
    background: #0177ff;
    border: none;
    padding: 10px 5px;
    border-radius: 5px;
    color: #fff;
    font-size: 20px;
    margin: 20px 0px;
}
.carimg img{max-width: 100%;}
.paymentwrapper{padding-top: 100px !important;}
.customradio {
  position: absolute !important;
  right: 50px  !important;
  bottom: 15px  !important;
}
