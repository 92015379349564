.userinformationform {
    position: relative;
    width: 50%;
    margin: 100px auto;
    box-shadow: 0px 0px 2px 2px #ccc;
    padding: 20px;
    border-radius: 10px;
}
.control-group {
  float: left;
    width: 50%;
    vertical-align: top;
    padding: 0px 10px;
    margin-bottom: 15px;
}
.input-xlarge {
    width: 100%;
    height: 34px;
    border: solid 1px #b7acac;
    padding-left: 10px;
}
span.genradio {
    display: inline-block;
}
.control-groupcustome {
    padding: 0px 10px;
}
.control-groupcustome {
    padding: 0px 10px;
}
textarea.custometxt {
    width: 100%;
    margin: 15px 0px;
    display: inline-block;
    height: 100px;
    resize: none;
    margin-top: 1px;
}
.btnwrapper {
    margin-top: 15px;
}
span.edit-profile {
  float: right;
  cursor: pointer;
}
@media(max-width:767px){
  .control-group {width: 100%;}
  .userinformationform{width:100%;}
}
.controls {
  position: relative;
}
.invalid {
  position: absolute;
  top: 35px;
  margin-bottom: 10px;
}
