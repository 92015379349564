@font-face {
  font-family: 'gilroy';
  font-style: normal;
  font-weight: 400;
  src: url('gilroy.eot'); /* IE9 Compat Modes */
  src: local('gilroy'), local('sans-serif'),
       url('gilroy.eot') format('woff2'), /* Super Modern Browsers */
       url('gilroy.woff') format('woff'), /* Modern Browsers */
       url('gilroy.ttf') format('truetype'), /* Safari, Android, iOS */
  }
body{
   padding: 0px;
   margin: 0px;
   font-family: 'gilroy', sans-serif;
   font-weight: 400;
   color: #00145a;
}
a:focus,a:hover,button:focus,button:hover{
   outline: 0!important;
}
a:focus,a:hover,button:focus,button:hover{
  text-decoration: none!important;
}
.pad-0{
   padding:0px!important;
}
.color-pink{
   color: rgb(235, 77, 75)!important;
}
.btnBlue{
   border: 0px none rgb(82, 104, 219);
   background-color: rgb(82, 104, 219);
background-image: linear-gradient(31deg, rgba(215, 178, 233, 0.4) 0%, rgba(83, 105, 220, 0.4) 100%);
transition: all 0.3s ease 0s;
color: rgb(255, 255, 255);
}
.btnBlue:hover{
  background-color: #fff !important;
  border: solid 1px #fff !important;
  }
.btnTransparent{
   border: 1px solid;
    border-top-color: currentcolor;
    border-right-color: currentcolor;
    border-bottom-color: currentcolor;
    border-left-color: currentcolor;
   transition: all 0.3s ease 0s;
   background-color: transparent;
   color: rgb(81, 103, 219);
}
.mar-lf-auto{
  margin-left: auto;
}
header {
   position: fixed;
   top: 0px;
   left: 0px;
   width: 100%;
   transition: all 0.35s ease-in-out 0s;
   padding: 30px 0px;
   z-index: 9;
   background: #fff;
}
.custom_header{
  background: rgba(255, 255, 255, .94);
  border-bottom: 1px solid #bebfc0;
 }
header .navbar-light .navbar-brand{
   margin-right: 166px;
}
header .navbar-light .navbar-brand img{
   max-width: 300px !important;
}
header .navbar-light .navbar-nav .nav-link{
   padding: 5px;
   font-size: 16px;
   font-family: 'Gilroy', sans-serif;
   font-weight: 400;
   transition: all 0.15s ease-in-out 0s;
   color: rgb(52, 61, 72);
}

header .navbar-light .navbar-nav  .nav-item.active .nav-link,header .navbar-light .navbar-nav .nav-link:hover{
   color: rgb(235, 77, 75);
}
header .navbar-light .navbar-nav  .nav-item{
   padding-right:24px;
}


.topBannerWrap{
   padding-top: 210px;
   padding-bottom: 20px;
   background-image: url("../images/PatakaPDF_HeroBackgroundImage.png") !important;
   background-size: cover;
   background-position: center center;
   background-repeat: no-repeat;
   background-attachment: fixed;
   min-height: 100vh;
   position: relative;
   overflow: hidden;
}

.topBannerWrap .discountInfo{
   display: inline-block;
   border-radius: 4em;
   border: 1px solid rgb(241, 244, 246);
   padding: 6px 25px;
   box-shadow: rgba(22, 53, 76, 0.05) 0px 7px 25px 0px;
   margin-bottom: 30px;
   background-color: rgb(255, 255, 255);
}
.topBannerWrap .discountInfo span{
   box-sizing: border-box;
   margin-bottom: 0px;
   margin-top: 0px;
   font-size: 14px;
   color: rgb(15, 33, 55);
   font-weight: 500;
}
.topBannerWrap .discountInfo span.color-pink{
   box-sizing: border-box;
   margin-bottom: 0px;
   margin-right: 0.4em;
   margin-top: 0px;
   font-size: 14px;
   font-weight: 700;
}

.topBannerWrap .headingWrap h1{
   color: rgb(15, 33, 55);
   font-weight: 700;
   letter-spacing: -0.025em;
   margin-bottom: 25px;
   font-size: 55px;
}
.topBannerWrap .headingWrap p{
   box-sizing: border-box;
   margin-bottom: 0px;
   margin-top: 0px;
   font-size: 16px;
   color: rgba(52, 61, 72, 0.8);
   line-height: 1.75;
   font-weight: 400;
}
.topBannerWrap .buttonWrap{
   margin-top: 40px;
}
.topBannerWrap .buttonWrap .btnBlue{
   min-width: 156px;
   cursor: pointer;
   display: inline-flex;
   -moz-box-align: center;
   align-items: center;
   -moz-box-pack: center;
   justify-content: center;
   min-height: 46px;
   border-radius: 3px;
   font-family: inherit;
   font-weight: 500;
   text-decoration: none;
   text-transform: uppercase;
   padding: 8px 15px;
   font-size: 14px;
   background-color: #0041FF;
   border: solid 1px #0041FF;
}
.topBannerWrap .buttonWrap .btnTransparent{
   cursor: pointer;
   display: inline-flex;
   -moz-box-align: center;
   align-items: center;
   -moz-box-pack: center;
   justify-content: center;
   min-height: 46px;
   border-radius: 3px;
   font-family: inherit;
   font-weight: 500;
   text-decoration: none;
   text-transform: uppercase;
   padding: 8px 15px;
   font-size: 14px;
   margin-left: 18px;
   min-width: 156px;
   border-color: rgba(82, 104, 219, 0.2);
}
.topBannerWrap .leftSection{
   width: 50%;
   float: left;
}
.topBannerWrap .rightSection{
  position: relative !important;
  width: 50%;
  float: right;
}
.topBannerWrap .objectWrapper {
    margin-left: auto;
    position: relative;
}
.topBannerWrap .objectWrapper img{
   display: block;
   max-width: 100%;
   height: auto;
   box-sizing: border-box;
   margin: 0px;
}
.dashboardWrapper {
    position: absolute;
    top: 0px;
    right: 0px;
}
.ourServicesWrap{
  padding-bottom: 180px;
}
.ourServicesWrap .featureBlock .imgWrapper{
   position: relative;
   background: rgba(0, 0, 0, 0) linear-gradient(-60deg, rgba(236, 0, 140, 0.75), rgba(255, 103, 103, 0.75)) repeat scroll 0% 0%;
   border-bottom-left-radius: 6px;
 overflow: hidden;
 margin-bottom: 30px;
 height: 84px;
 width: 84px;
 -moz-box-align: center;
align-items: center;
-moz-box-pack: center;
justify-content: center;
color: rgb(255, 255, 255);
border-radius: 50%;
display: flex;
border-bottom-left-radius: 6px;
}
.ourServicesWrap .featureBlock .imgWrapper:before {
    transform: rotate(45deg);
    background-color: rgba(255, 255, 255, 0.15);
}
.ourServicesWrap .featureBlock .imgWrapper:after{
   background-color: rgba(0, 0, 0, 0.05);
   transform: rotate(-45deg);
}
.ourServicesWrap .featureBlock .imgWrapper:after,.ourServicesWrap .featureBlock .imgWrapper:before{
   content: "";
   width: 28px;
   height: calc(100% + 30px);
   position: absolute;
}

.ourServicesWrap .featureBlock .imgWrapper img{
   width: 36px;
}
.ourServicesWrap h2 span,.pricinSecWrap h2 span,.faqWrap h2 span,.freeTrialWrap h2 span{
   margin-bottom: 10px;
   margin-top: 0px;
   font-size: 14px;
   color: rgb(82, 104, 219);
   display: block;
   font-weight: 700;
   text-align: center;
   letter-spacing: 0.15em;
}

.ourServicesWrap h2,.pricinSecWrap h2,.faqWrap h2,.freeTrialWrap h2{
   color: rgb(15, 33, 55);
font-weight: 500;
text-align: center;
letter-spacing: -0.025em;
font-size: 24px;
margin-bottom: 80px;
}
.freeTrialWrap h2{margin-top: 80px;}
.ourServicesWrap .featureBlock h3{
   margin-bottom: 20px;
   color: rgb(15, 33, 55);
   font-weight: 400;
   line-height: 1.5;
   letter-spacing: -0.02em;
   margin-top: 0px;
   font-size: 20px;
}
.ourServicesWrap .featureBlock p{
    margin-bottom: 1rem;
    margin-top: 0px;
    font-size: 15px;
    color: rgba(52, 61, 76, 0.8);
    line-height: 1.75;
}
.howItWorkWrap .objectWrap{
   margin-right: auto;
   position: relative;
}
.howItWorkWrap .innerleftsec{
   position: absolute;
   width: 55%;
   height: 100%;
   top: 0px;
   left: 0px;
   display: flex;
   -moz-box-align: center;
   align-items: center;
   -moz-box-pack: end;
   justify-content: flex-end;
}
.howItWorkWrap .objectWrap img,.howItWorkWrap .dashboardWrap img{
   max-width: 93%;
  height: auto;
}
.howItWorkWrap .dashboardWrap{
    position: absolute;
    left: 0px;
    top: 4vw;
}
.howItWorkWrap{
   min-height: 630px;
display: flex;
-moz-box-align: center;
align-items: center;
margin-bottom: 40px;
position: relative;
}
.howItWorkWrap .innerRightSection{
   margin-left: 58%;
   width: 45%;
}
.howItWorkWrap .innerRightSection h2 {
    max-width: 440px;
    font-size: 36px;
    color: rgb(15, 33, 55);
    font-weight: 400;
    line-height: 1.5;
    letter-spacing: -0.01em;
    margin-top: 0px;
    margin-bottom: 20px;
 }
 .howItWorkWrap .innerRightSection p{
   font-size: 16px;
color: rgba(52, 61, 72, 0.8);
line-height: 1.75;
margin-bottom: 33px;
margin-top: 0px;
max-width: 440px;
 }
  .howItWorkWrap .innerRightSection a{
   cursor: pointer;
   display: inline-flex;
   -moz-box-align: center;
   align-items: center;
   -moz-box-pack: center;
   justify-content: center;
   min-height: 46px;
   border-radius: 3px;
   font-family: inherit;
   font-weight: 500;
   text-decoration: none;
   text-transform: capitalize;
   padding: 8px 22px;
   border: 0px none rgb(82, 104, 219);
   transition: all 0.3s ease 0s;
   background-color: rgb(82, 104, 219);
   background-image: linear-gradient(31deg, rgba(215, 178, 233, 0.4) 0%, rgba(83, 105, 220, 0.4) 100%);
   box-sizing: border-box;
   min-width: 156px;
   font-size: 14px;
   color: rgb(255, 255, 255);
   display: inline-block;
   display: flex;
   max-width: 156px;
   flex:0 0 156px;
}
.ourServicesWrap.ourFeaturesWrap .featureBlock .imgWrapper::after, .ourServicesWrap.ourFeaturesWrap .featureBlock .imgWrapper::before{
   content: none;
}
.ourServicesWrap.ourFeaturesWrap .featureBlock .imgWrapper{
   border-bottom-right-radius: 6px;
   border-bottom-left-radius: 50%;
}
.ourServicesWrap.ourFeaturesWrap .appDevelopment .imgWrapper{
   background: #fff5f6;
}
.ourServicesWrap.ourFeaturesWrap .TimesAward .imgWrapper{
   background:#ebfff2;
}
.ourServicesWrap.ourFeaturesWrap .cloudStroage .imgWrapper{
   background:#f1faff;
}
.ourServicesWrap.ourFeaturesWrap .clientSatisfaction .imgWrapper{
   background:#fffae8;
}
.ourServicesWrap.ourFeaturesWrap .uxPlanning .imgWrapper{
   background:#f5eeff;
}
.ourServicesWrap.ourFeaturesWrap .customerSupport .imgWrapper{
   background:#ffecfa;
}

.ourServicesWrap.ourFeaturesWrap .featureBlock {
   display: flex;
   padding: 20px 0px;
}
.ourServicesWrap.ourFeaturesWrap .imgWrapper{
   flex: 0 0 84px;
   max-width: 84px;
}
.ourServicesWrap.ourFeaturesWrap .contentWrap{
   padding-left: 20px;
   flex: 1;
}
.ourServicesWrap.ourFeaturesWrap .featureBlock h3{
   margin-bottom: 15px
}
.pricinSecWrap{
   padding-top: 80px;
   padding-bottom: 80px;
}
.pricinSecWrap .nav-tabs .nav-link{
   font-size: 16px;
   font-weight: 400;
   color: rgb(111, 122, 135);
   background: rgb(255, 255, 255) none repeat scroll 0% 0%;

   height: 50px;
   width: 165px;
   border: 1px solid rgb(228, 233, 238);
   text-align: center;
   display: inline-flex;
   justify-content: center;
   align-items: center;
   border-radius: 5px;
}
.pricinSecWrap .nav-tabs .nav-item.show .nav-link,.pricinSecWrap .nav-tabs .nav-link.active{
   color: rgb(81, 103, 219);
border-color: rgb(81, 103, 219);
}
.pricinSecWrap .nav-tabs li:first-child .nav-link{

   border-top-right-radius: 0px;
   border-bottom-right-radius: 0px;
}
.pricinSecWrap .nav-tabs li:last-child .nav-link{

   border-top-left-radius: 0px;
   border-bottom-left-radius: 0px;
}
.pricinSecWrap .nav-tabs{
   justify-content: center;
   border-bottom:0px;
   margin-bottom: 70px;
}
.pricinSecWrap h2{
   margin-bottom: 30px;
}

.pricingTabWrap .planWrap{
   border: 1px solid rgb(242, 244, 247);
   padding: 60px 45px;
   border-radius: 5px;
   margin-bottom: 30px;
}
.pricingTabWrap .planWrap h2{
   box-sizing: border-box;
   margin-bottom: 12px;
   margin-top: 0px;
   font-size: 22px;
   color: rgb(15, 33, 55);
   font-weight: 500;
   text-align: center;
   letter-spacing: -0.025em;
}
.pricingTabWrap .planWrap p{
   margin-bottom: 0px;
   margin-top: 0px;
   font-size: 16px;
   color: rgb(93, 100, 109);
   text-align: center;
   line-height: 1.75;
   margin-bottom: 40px;
}
.pricingTabWrap .planWrap p.price span{
   margin-bottom: 0px;

   margin-top: 0px;

   font-size: 40px;

   color: rgb(15, 33, 55);

   display: block;

   text-align: center;

   letter-spacing: -0.025em;

   display: block;

   padding: 0px;

   line-height: 1.2;
}
.pricingTabWrap .planWrap p.price{
   font-size: 14px;
   margin-bottom: 25px;
}
.pricingTabWrap .planWrap button,.pricingTabWrap .planWrap a{
   cursor: pointer;
display: inline-flex;
-moz-box-align: center;
align-items: center;
-moz-box-pack: center;
justify-content: center;
min-height: 46px;
min-width: 48px;
border-radius: 3px;
font-family: inherit;
font-weight: 500;
text-decoration: none;
text-transform: capitalize;
padding: 8px 10px;
transition: all 0.3s ease 0s;
color: rgb(82, 104, 219);
border: 1px solid rgb(82, 104, 219);
    border-top-color: rgb(82, 104, 219);
    border-right-color: rgb(82, 104, 219);
    border-bottom-color: rgb(82, 104, 219);
    border-left-color: rgb(82, 104, 219);
background-color: transparent;
box-sizing: border-box;
width: 222px;
max-width: 100%;
font-size: 14px;
}
.pricingTabWrap .planWrap button:hover,.pricingTabWrap .planWrap a:hover{
   color: rgb(255, 255, 255);
background-color: rgb(82, 104, 219);
border-color: transparent;
box-shadow: rgba(82, 104, 219, 0.57) 0px 9px 20px -5px;
background-image: linear-gradient(31deg, rgba(215, 178, 233, 0.4) 0%, rgba(83, 105, 220, 0.4) 100%);
}
.pricingTabWrap .planWrap ul li{
   color: rgb(93, 100, 109);
   font-size: 16px;
   font-weight: 400;
   list-style: none;
   margin-bottom: 20px;
   position: relative;
   padding-left: 35px;
}
.pricingTabWrap .planWrap ul{
   margin-top: 40px;
}
.pricingTabWrap .planWrap ul li:before{
   content: '';
   position: absolute;
   left: 0px;
   top: 3px;
   background: url(../images/checked.png);
   height: 19px;
   width: 19px;
   background-size: 100% 100%;
}
.pricingTabWrap .planWrap button.btnBlue,.pricingTabWrap .planWrap a.btnBlue{
   border: 0px none rgb(82, 104, 219);
transition: all 0.3s ease 0s;
background-color: rgb(82, 104, 219);
background-image: linear-gradient(31deg, rgba(215, 178, 233, 0.4) 0%, rgba(83, 105, 220, 0.4) 100%);
color: #fff
}

.ourServicesWrap.ourFeaturesWrap{
  padding-top: 150px;
  padding-bottom: 40px;
}
.clientsCommentWrap{
  padding: 80px 0px 120px;
}
.clientImageWrap .imgBgGradientWrap{
    width: 256px;
    height: 256px;
    border-radius: 50% 50% 10px;
    box-sizing: border-box;
    background: rgba(0, 0, 0, 0) radial-gradient(circle at center center, rgb(232, 230, 192) 0%, rgb(199, 195, 134) 100%) repeat scroll 0% 0%;
    overflow: hidden;
}
.clientImageWrap .imgBgGradientWrap img{
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: top center;
}
.clientImageWrap{
  width: 256px;
  height: 285px;
  position: relative;
}
.clientsCommentWrap .clientCommenttext{
  margin: 0px auto 20px;
  padding-left: 0px;
  max-width: 954px;
  font-size: 14px;
  color: rgb(82, 104, 219);
  display: block;
  font-weight: 700;
  letter-spacing: 0.15em;
  margin-left: 0px;
}

.clientCommentsInnerWrap{
  margin-top: 0px;
  font-size: 30px;
  color: rgb(15, 33, 55);
  font-weight: 400;
  line-height: 1.72;
}
.clientsCommentWrap .clientName span{
  margin-bottom: 0px;
  margin-top: 0px;
  font-size: 16px;
  color: rgb(52, 61, 72);
  font-weight: 500;
  line-height: 30px;
}
.clientsCommentWrap .clientName{
  margin-top: 0px;
  font-size: 16px;
  color: rgba(52, 61, 72, 0.8);
  font-weight: 400;
  line-height: 30px;
}
.clientsCommentWrap p img{
  width: 20px;
  position: relative;
  top: -13px;
}

.clientsCommentWrap .socialMediaicon{
  position: absolute;
  z-index: 2;
  bottom: 25px;
  left: 20px;
  width: 65px;
  height: 65px;
  font-size: 26px;
  background-color: rgb(220, 57, 95);
  box-shadow: rgba(23, 65, 104, 0.2) 0px 10px 28.2px 1.8px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
}
.clientsCommentWrap .socialMediaicon img{
  width: 30px;
}
.clientsCommentWrap .carousel-control-next, .clientsCommentWrap .carousel-control-prev{
  height: 19px;
  bottom: 0px;
  top: auto;
  width: 24px;
}
.clientsCommentWrap .carousel-control-next{
  left: 40px;
  transform: rotate(180deg);
}
.clientSliderContentWrap{
  max-width: 950px;
}
.faqWrap{
  background: #fafbff;
  padding: 80px 0px;
}
.faqWraptextWrap .card-header{
  border:none;
border-bottom: 0px solid rgb(235, 235, 235);
background: transparent;
padding: 20px 30px;
}
.faqWraptextWrap .btn.btn-link{
  margin-bottom: 0px;
margin-top: 0px;
font-size: 21px;
color: rgb(15, 33, 55);
font-weight: 400;
letter-spacing: -0.025em;
padding: 0px;
position: relative;
width: 100%;
text-align: left;
padding-right: 20px;
}
.faqWraptextWrap .card-body{
  padding: 20px 30px;
  margin-bottom: 0px;
  margin-top: 0px;
  font-size: 18px;
  color: rgb(93, 100, 109);
  font-weight: 400;
  line-height: 1.75;
  padding-top: 0px;
}
.faqWraptextWrap .btn.btn-link:hover{
  text-decoration: none;
}
.faqWraptextWrap .card{
  border:none;
  border-bottom: 1px solid rgb(235, 235, 235);
}
.faqWrap .free-trialBtn:hover{
  color: rgb(255, 255, 255);

background-color: rgb(82, 104, 219);

border-color: transparent;

box-shadow: rgba(82, 104, 219, 0.57) 0px 9px 20px -5px;

background-image: linear-gradient(31deg, rgba(215, 178, 233, 0.4) 0%, rgba(83, 105, 220, 0.4) 100%);
}
.faqWrap .free-trialBtn{
  cursor: pointer;
    display: inline-flex;
    -moz-box-align: center;
    align-items: center;
    -moz-box-pack: center;
    justify-content: center;
    min-height: 46px;
    min-width: 48px;
    border-radius: 3px;
    font-family: inherit;
    font-weight: 500;
    text-decoration: none;
    text-transform: capitalize;
    padding: 8px 22px;
    transition: all 0.3s ease 0s;
    color: rgb(82, 104, 219);
    border: 1px solid rgb(82, 104, 219);
    background-color: transparent;
    box-sizing: border-box;
    height: 48px;
    font-size: 14px;
    min-width: 166px;
    margin-top: 50px;

}
.faqWraptextWrap .card {
  border-bottom: 1px solid rgb(235, 235, 235)!important;
}
.faqWraptextWrap{
  max-width: 820px;
  margin: auto;
}
.faqWraptextWrap .btn.btn-link:after{
  content: '';
  background-image: url('../images/remove.png');
  background-size: 100% 100%;
  position: absolute;
  height: 15px;
  width: 15px;
  top: 6px;
  right: 0px;
}
.faqWraptextWrap .btn.btn-link.collapsed:after{
  content: '';
  background-image: url('../images/add-faq.png');
  background-size: 100% 100%;
  position: absolute;
  height: 15px;
  width: 15px;
  top: 6px;
  right: 0px;
}




  .uploadDocumentEnterDocumentWrap .active{
  display: block;
  }
  .uploadDocumentEnterDocumentWrap .hide{
      display: none;
  }
  .uploadDocumentEnterDocumentWrap .activelink{
    color: red;
  }
  .container-940{
    max-width: 970px;
    margin: auto;
    padding-left: 15px;
    padding-right: 15px;
  }
  .uploadDocumentEnterDocumentWrap{
    padding:40px 0px;
    padding-top: 0px;
  }
  .uploadDocumentEnterDocumentWrap .tabWrap{
    background: #fff;
    padding: 0px;
    box-shadow: 0 0px 8px 0 rgb(235, 235, 235);
  }
  .uploadDocumentEnterDocumentWrap .tabWrap ul{
    list-style: none;
    padding-left: 0px;
    margin-top:0px;
    margin-bottom: 0px;
  }
  .uploadDocumentEnterDocumentWrap .tabWrap{
    display: flex;
  }
  .uploadDocumentEnterDocumentWrap .tabNav{
    flex:0 0 200px;
    max-width: 200px;
    background: #f7f7f7;
  }
  .uploadDocumentEnterDocumentWrap .tabContentWrap{
    flex: 1;
  }
  .uploadDocumentEnterDocumentWrap .tabNav li a{
    font-family: 'Gilroy', sans-serif;
    font-size: 16px;
    line-height: 16px;
    font-weight: 500;
    color: #444;
    text-decoration: none;
    display: flex;
    align-items: center;
    padding: 13px 20px;
    padding-left: 15px;
    background: #f7f7f7;
    border-left: 4px solid #f7f7f7;
    border-bottom:1px solid rgb(235, 235, 235);
    border-right: 1px solid rgb(235, 235, 235);
  }

   .uploadDocumentEnterDocumentWrap .tabNav li a.activelink,.uploadDocumentEnterDocumentWrap .tabNav li a:hover{
       background:#fff;
       border-left: 4px solid rgb(82, 104, 219);
   }
   .uploadDocumentEnterDocumentWrap .tabNav li a.activelink{
       border-right: 0px;
   }


  .uploadDocumentEnterDocumentWrap .activelink i{
    color: rgb(82, 104, 219);
  }
  .uploadDocumentEnterDocumentWrap .tabNav li a i{
    max-width: 60px;
    flex: 0 0 60px;
    font-size: 36px;
  }
  .uploadDocumentEnterDocumentWrap .list{
    padding: 23px;
  }
  /*custom browse button*/
    .upload-btn-wrapper {
      position: relative;
      overflow: hidden;
      display: inline-block;
    }
    .innercontant {
      border: 2px dashed #ebebeb;
      padding: 6px 5px 6px 5px;
      text-align: center;
  }

    .innercontant .btn {
      border: 2px dashed rgb(235, 235, 235);
      color: gray;
      background-color: white;
      padding: 25px;
      border-radius: 8px;
      font-size: 20px;
       font-weight: 400;
       font-family: 'Gilroy', sans-serif;
    }
    .upload-btn-wrapper .btn .bold{
       font-weight: 700;
    }
    .upload-btn-wrapper .btn p{
       margin-bottom: 15px;
        margin-top: 0px;
        font-size: 16px;
        color: rgba(52, 61, 72, 0.8);
        line-height: 1.75;
        font-weight: 400;
    }
    .upload-btn-wrapper .btn h2{
       font-size: 24px;
       margin: 0 0 5px;
       color: #444;
       line-height: 27px;
       font-weight: 400;
       font-family: 'Gilroy', sans-serif;
    }
    .upload-btn-wrapper input[type=file] {
      font-size: 100px;
      position: absolute;
      left: 0;
      top: 0;
      opacity: 0;
      height: 100%;
      cursor: pointer;
    }
    .browseForDocumentBtn{
       padding: 10px 20px;
       color: #fff;
       position: relative;
       vertical-align: middle;
       line-height: 20px;
       text-align: center;
       font-size: 14px;
       font-weight: 700;
       white-space: nowrap;
       transition: border .15s;
       border-style: solid;
       border-width: 1px;
       border-radius: 3px;
       width: auto;
       margin:auto;
      border: 0px none rgb(82, 104, 219);
      background-color: rgb(82, 104, 219);
      background-image: linear-gradient(31deg, rgba(215, 178, 233, 0.4) 0%, rgba(83, 105, 220, 0.4) 100%);
      transition: all 0.3s ease 0s;
      color: rgb(255, 255, 255);
       display: inline-block;
       cursor: pointer;
    }
    .dragDropDocumentWrap:hover .browseForDocumentBtn{
      background-color: rgb(82, 104, 219);
      border-color: rgb(82, 104, 219);
      box-shadow: rgba(82, 104, 219, 0.57) 0px 9px 20px -5px;
    }
    .tabContentWrap .clickme2 .activelink,.tabContentWrap .clickme2 a:hover{
       border-color: rgb(82, 104, 219) !important;

    }
    .tabContentWrap .clickme2 a{
       height: 56px;
       width: 86px;
       background: #fff;
       display: block;
       -webkit-transition: border .2s;
       -o-transition: border .2s;
       transition: border .2s;
       border: 1px solid #fff !important;
       cursor: pointer;
       text-align: center;
       outline: none;
       border-radius: 5px;
    }
     .typeOFFileWrap{
       display: flex;
       justify-content: center;
       padding-top: 30px;
    }
    .typeOFFileWrap li a{
       display: inline-flex!important;
       justify-content: center;
       align-items: center;
    }
    .typeOFFileWrap li{
       margin-left: 5px;
       margin-right: 5px;
    }
    .urlDocumentWrap h2{
       font-size: 24px;
       margin: 0 0 5px;
       color: #444;
       line-height: 27px;
       font-weight: 400;
       font-family: 'Gilroy', sans-serif;
       text-align: center;
    }
    .urlDocumentWrap p{
       color: #666;
       font-size: 15px;
       line-height: 20px;
       font-family: 'Gilroy', sans-serif;
       font-weight: 400;
       text-align: center;
        margin-bottom: 15px;
        margin-top: 0px;
        font-size: 16px;
        color: rgba(52, 61, 72, 0.8);
        line-height: 1.75;
        font-weight: 400;
    }
    .urlDocumentWrap p .bold{
       font-weight: 700;
    }
    .fillNowanchorWrap input{
       border:1px solid rgb(235, 235, 235);
       border-radius: 3px;
       position: relative;
       padding: 10px;
       line-height: 20px;
       color: #444;
       font-size: 14px;
       background: #fff;
       -webkit-transition: border-color .15s ease-in-out;
       -o-transition: border-color .15s ease-in-out;
       transition: border-color .15s ease-in-out;
       -webkit-box-shadow: inset 0px 0px 0px 0 rgba(0,1,1,.07);
       box-shadow: inset 0px 0px 0px 0 rgba(0,1,1,.07);
    }
    .fillNowanchorWrap button{
       padding: 10px;
       color: #fff;
       position: relative;
       vertical-align: middle;
       line-height: 20px;
       text-align: center;
       font-size: 14px;
       font-weight: 700;
       white-space: nowrap;
       -webkit-transition: border .15s;
       -o-transition: border .15s;
       transition: border .15s;
       border-style: solid;
       border-width: 1px;
       border-radius: 3px;
       border: 0px none rgb(82, 104, 219);
      background-color: rgb(82, 104, 219);
      background-image: linear-gradient(31deg, rgba(215, 178, 233, 0.4) 0%, rgba(83, 105, 220, 0.4) 100%);
      transition: all 0.3s ease 0s;
      color: rgb(255, 255, 255);
      display: inline-block;
      cursor: pointer;

    }
    .fillNowanchorWrap button:hover{
      background-color: rgb(82, 104, 219);
      border-color: rgb(82, 104, 219);
      box-shadow: rgba(82, 104, 219, 0.57) 0px 9px 20px -5px;
    }
    .fillNowanchorWrap{
       display: flex;
    }
    .fillNowanchorWrap input{
       flex:1;
       margin-right: 10px;
    }
    .fillNowanchorWrap button{
       flex: 0 0 152px;
       max-width: 152px;
       cursor: pointer;
    }
    .fillNowanchorWrap input::-webkit-input-placeholder { /* Chrome/Opera/Safari */
      color: #888;
      opacity: 1;
    }
    .fillNowanchorWrap input::-moz-placeholder { /* Firefox 19+ */
      color: #888;
      opacity: 1;
    }
    .fillNowanchorWrap input:-ms-input-placeholder { /* IE 10+ */
      color: #888;
      opacity: 1;
    }
    .fillNowanchorWrap input:-moz-placeholder { /* Firefox 18- */
      color: #888;
      opacity: 1;
    }
  /*end*/
  .urlDocumentWrap {
    background:url ('../images/host.jpg') 10px -9px no-repeat;
    text-align: center;
    background-size: 350px;
    min-height: 329px;
  }
  .urlDocumentWrap.active{
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 283px;
  }
  .processingWrap{
    border: 1px solid #ddd;
    padding:20px;
    border-radius: 5px;
  }
  .processingWrap h2{
    font-size: 24px;
    margin: 0 0 5px;
    color: #444;
    line-height: 27px;
    font-weight: 400;
    font-family: 'Gilroy', sans-serif;
    text-align: center;
  }
  .processingWrap ul{
    padding: 0px;
  }
  .processingWrap li{
    color: #666;
    font-size: 15px;
    line-height: 20px;
    font-weight: 400;
    font-family: 'Gilroy', sans-serif;
    margin-bottom: 12px;
    list-style: none;
  }
  .progress {
     height: 12px;
     margin-bottom: 20px;
     overflow: hidden;
     background-color: #f5f5f5;
     border-radius: 10px;
     -webkit-box-shadow: inset 0 1px 2px rgba(0,0,0,.1);
     box-shadow: inset 0 1px 2px rgba(0,0,0,.1);
     margin-top: 40px;
     margin-bottom: 40px;
  }
  .progress-bar {
     float: left;
     width: 0%;
     height: 100%;
     font-size: 12px;
     line-height: 20px;
     color: #fff;
     text-align: center;
     background-color: #ff9000;
     -webkit-box-shadow: inset 0 -1px 0 rgba(0,0,0,.15);
     box-shadow: inset 0 -1px 0 rgba(0,0,0,.15);
     -webkit-transition: width .6s ease;
     -o-transition: width .6s ease;
     transition: width .6s ease;
  }
  .progress-bar .sr-only {
     position: absolute;
     width: 1px;
     height: 1px;
     padding: 0;
     margin: -1px;
     overflow: hidden;
     clip: rect(0,0,0,0);
     border: 0;
  }
  .processingWrap li:before{
    content: '\f00c';
    font-size: 16px;
    position: absolute;
    left: 0px;
    font-family: fontawesome;
    color: #71b52c;

  }
  .processingWrap li{
    position: relative;
    padding-left: 30px;
  }
  .processingWrap li.onprogress:before{
    content: '\f2f1';
    -webkit-animation: rotation 2s infinite linear;
    color: #ff9000
  }
  #file-upload{
    display: none;
  }
  @-webkit-keyframes rotation {
    from {
       -webkit-transform: rotate(0deg);
    }
    to {
       -webkit-transform: rotate(359deg);
    }
  }

  .freeTrialWrap{
    padding-bottom: 80px;
  }

  .whanttoKnowWhenLaunch{
    padding: 80px 0px;
    padding-bottom: 40px;
    background: #fafbff;
  }
  .whanttoKnowWhenLaunch h2{
    font-size: 36px;
    color: rgb(15, 33, 55);
    font-weight: 400;
    line-height: 1.5;
    letter-spacing: -0.01em;
    margin-top: 0px;
  }
  .whanttoKnowWhenLaunch p{
    font-size: 16px;
    color: rgba(52, 61, 72, 0.8);
    line-height: 1.75;
    margin-bottom: 33px;
    margin-top: 0px;
  }
  .subscribeBtn{
    border: 0px none rgb(82, 104, 219);
    transition: all 0.3s ease 0s;
    background-color: rgb(82, 104, 219);
    background-image: linear-gradient(31deg, rgba(215, 178, 233, 0.4) 0%, rgba(83, 105, 220, 0.4) 100%);
    color: #fff;
    cursor: pointer;
    display: inline-flex;
    -moz-box-align: center;
    align-items: center;
    -moz-box-pack: center;
    justify-content: center;
    min-height: 46px;
    min-width: 48px;
    border-radius: 3px;
    font-family: inherit;
    font-weight: 500;
    text-decoration: none;
    text-transform: capitalize;
    padding: 8px 10px;
    flex: 0 0 150px;
    max-width: 150px;
  }
  .subscribeBtn:hover{
    color: rgb(255, 255, 255);
    background-color: rgb(82, 104, 219);
    border-color: transparent;
    box-shadow: rgba(82, 104, 219, 0.57) 0px 9px 20px -5px;
    background-image: linear-gradient(31deg, rgba(215, 178, 233, 0.4) 0%, rgba(83, 105, 220, 0.4) 100%);
  }
  .subscribeInputWrap > div{
    flex: 1;
    padding-right: 10px;
  }
  .subscribeInputWrap input{
    border-radius: 3px;
    height: 46px;
    border:1px solid rgb(235, 235, 235) !important;
  }
  .subscribeInputWrap input:hover,.subscribeInputWrap input:focus{
    box-shadow: 0px 0px 0px 0px;
    border:1px solid rgb(235, 235, 235) !important;
    border-radius: 3px;
  }
  .subscribeInputWrap{
    display: flex;
    padding-top: 13px;
  }

  footer{
    background-repeat: no-repeat;
    background-position: center 0px;
    padding-top: 30px;
    padding-bottom: 30px;
    position: relative;
    background-color: #f1f1f5;
  }
  footer img{
    max-width: 300px;
  }

  header .navbar-light .navbar-nav .nav-link{
    cursor: pointer;
    display: inline-flex;
    -moz-box-align: center;
    align-items: center;
    -moz-box-pack: center;
    justify-content: center;
    min-height: 46px;
    min-width: 48px;
    border-radius: 3px;
    font-family: inherit;
    font-weight: 500;
    text-decoration: none;
    text-transform: capitalize;
    padding: 8px 10px;
    transition: all 0.3s ease 0s;
    color: rgb(82, 104, 219);
    border: 1px solid rgb(82, 104, 219);
    width: auto !important;
    max-width: 100%;
    font-size: 14px;
    text-transform: uppercase;
  }
   header .navbar-light .navbar-nav .nav-link:hover,header .navbar-light .navbar-nav .nav-link:focus{
    color: rgb(255, 255, 255);
    background-color: rgb(82, 104, 219);
    border-color: transparent;
    box-shadow: rgba(82, 104, 219, 0.57) 0px 9px 20px -5px;
    background-image: linear-gradient(31deg, rgba(215, 178, 233, 0.4) 0%, rgba(83, 105, 220, 0.4) 100%);
  }
  header .navbar-expand-lg .navbar-nav{
    margin-left: auto;
  }
  header .navbar-light .navbar-nav .nav-item:last-child{
    padding-right: 0px;
  }
 header .navbar-toggler span{
  display: block;
  width: 100%;
  height: 2px;
  margin: 4px 0px;
  margin-top: 4px;
  float: right;
  background-color: rgb(235, 77, 75);
  transition: all 0.3s ease 0s;
 }
  header .navbar-toggler span:last-child{
    width: calc(100% - 10px);
    margin-bottom: 0px;
  }
  header .navbar-toggler:hover span:last-child{
    width: 100%;
  }
  header .navbar-toggler{
    border: 0px none;
    background: transparent none repeat scroll 0% 0%;
    width: 50px;
    height: 30px;
    cursor: pointer;
  }
  header .navbar-toggler.collapsed span:first-child{
    transform: rotate(0deg);
    transform-origin: 0px 0% 0px;
  }
  header .navbar-toggler span:first-child{
    transform: rotate(45deg);
    transform-origin: 11px 50% 0px;
  }
  header .navbar-toggler.collapsed span:last-child{

    transform: rotate(0deg);
    transform-origin: 0px 0% 0px;
  }
  header .navbar-toggler span:last-child{
    width: 100%;
    transform: rotate(-45deg);
    transform-origin: 0px 50% 0px;
  }
  header .navbar-toggler.collapsed span:nth-child(2){
    display:block;
  }
  header .navbar-toggler span:nth-child(2){
    display:none;
  }
  header .navbar-collapse.show{
    position: absolute;
    background: #fff;
    width: 100%;
    left: 0px;
    right: 0px;
    padding-bottom: 35px;
    padding-left: 15px;
    padding-right: 15px;
    top: 59px;
    box-shadow: 0px 4px 7px 0px rgba(0,0,0,.2);
  }

  .loginModalWrap.modal-dialog{
    max-width: 80%;
    margin-left: auto;
    margin-right: auto;
  }
  .loginModalWrap .modal-content{
    overflow-x: hidden;
  }
  .loginRegisterFormWrap .logoImg{
    width: 128px;
  }
  .loginRegisterFormWrap .nav li{
    margin-right: 0px;
    text-align: center;
    max-width: 50%;
    flex: 0 0 50%;
  }
  .loginRegisterFormWrap .nav li a.active{
      color: #108ee9;
  }
  .loginRegisterFormWrap .nav li a{
    font-size: 14px;
    line-height: 1.5;
    white-space: nowrap;
    color: #20201d;
    font-weight: 500;
    padding: 13px 0px;
    display: block;
    border-bottom: 2px solid #f3f3f3;
  }
  .loginRegisterFormWrap .nav li a.active{
    border-bottom: 2px solid #108ee9;
  }
  .loginRegisterFormWrap{
    max-width: 400px;
    padding: 46px
  }
 .loginRegisterFormWrap  h2{
    margin-bottom: 10px;
    margin-top: 35px;
    font-size: 50px;
    color: rgb(32, 32, 29);
    font-weight: 400;
    letter-spacing: -0.025em;
  }
  .loginRegisterFormWrap p{
    margin-bottom: 23px;
    margin-left: 1px;
    margin-top: 0px;
    font-size: 15px;
    color: rgba(52, 61, 72, 0.8);
    line-height: 26px;
    letter-spacing: -0.025em;
  }
  .loginRegisterFormWrap .nav{
      margin-top: 30px;
  }
  .loginRegisterFormWrap .loginWithGoogleBtn{
    font-size: 15px;
    font-weight: 700;
    margin-bottom: 45px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 15px;
    display: block;
    text-align: center;
    color: #20201d;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 13px;
    text-decoration: none;
  }
  .loginRegisterFormWrap .loginWithGoogleBtn img{
      height: 22px;
      margin-right: 23px;
  }
  .loginForgetWrap a{
    cursor: pointer;
    font-family: inherit;
    font-weight: 700;
    text-transform: capitalize;
    font-size: 14px;
    color: rgb(16, 172, 132);
    margin-left: 30px;
  }
   .loginForgetWrap button{
      background-color: rgb(16, 172, 132);
      transition: all 0.3s ease 0s;
      cursor: pointer;
      display: inline-flex;
      -moz-box-align: center;
      align-items: center;
      -moz-box-pack: center;
      justify-content: center;
      color: rgb(255, 255, 255);
      background-color: rgb(16, 172, 132);
      min-height: 48px;
      border-radius: 3px;
      font-family: inherit;
      font-weight: 700;
      text-decoration: none;
      text-transform: capitalize;
      padding: 10px 20px;
      border: 0px none;
      transition: all 0.3s ease 0s;
      box-sizing: border-box;
      min-width: 156px;
      font-size: 14px;
   }
   .loginModalWrap  .modal-body{
    padding: 0px;
   }
   .loginModalWrap .formWrap input{
    font-size: 16px;

padding: 11px;

    padding-right: 11px;
    padding-left: 11px;

padding-right: 11px;

padding-left: 11px;

display: block;

width: 100%;

color: rgba(52, 61, 72, 0.8);

box-shadow: none;

border-radius: 4px;

box-sizing: border-box;

border: 1px solid rgb(235, 235, 235);

border-top-color: rgb(235, 235, 235);

border-top-style: solid;

border-top-width: 1px;

border-right-color: rgb(235, 235, 235);

border-right-style: solid;

border-right-width: 1px;

border-left-color: rgb(235, 235, 235);

border-left-style: solid;

border-left-width: 1px;

transition: border-color 0.2s ease 0s;

border: navajowhite;

    border-bottom-color: navajowhite;
    border-bottom-style: none;
    border-bottom-width: medium;

border-bottom: 1px solid #ebebeb;
border-radius: 0px;
   }
.loginModalWrap .formWrap .form-group{
  margin-bottom: 30px;
}

/*custom checkbox*/
/* Base for label styling */
.cust-checkboxes [type="checkbox"]:not(:checked),
.cust-checkboxes [type="checkbox"]:checked {
  position: absolute;
  left: -9999px;
}
.cust-checkboxes [type="checkbox"]:not(:checked) + label,
.cust-checkboxes [type="checkbox"]:checked + label {
  position: relative;
  padding-left: 1.95em;
  cursor: pointer;
  color: rgba(52, 61, 72, 0.8);
  margin-bottom: 30px;
}

/* checkbox aspect */
.cust-checkboxes [type="checkbox"]:not(:checked) + label:before,
.cust-checkboxes [type="checkbox"]:checked + label:before {
  content: '';
  position: absolute;
  left: 0; top: 0;
  width: 20px;
  height: 20px;
  border: 1px solid #dadada;
  background: #fff;
  border-radius: 4px;
}
/* checked mark aspect */
.cust-checkboxes [type="checkbox"]:not(:checked) + label:after,
.cust-checkboxes [type="checkbox"]:checked + label:after {
  content: '\2713\0020';
  position: absolute;
  top: 4px;
  left: 4px;
  font-size: 15px;
  line-height: 0.8;
  color: #09ad7e;
  transition: all .2s;

}
/* checked mark aspect changes */
.cust-checkboxes [type="checkbox"]:not(:checked) + label:after {
  opacity: 0;
  transform: scale(0);
}
.cust-checkboxes [type="checkbox"]:checked + label:after {
  opacity: 1;
  transform: scale(1);
}
/* disabled checkbox */
.cust-checkboxes [type="checkbox"]:disabled:not(:checked) + label:before,
.cust-checkboxes [type="checkbox"]:disabled:checked + label:before {
  box-shadow: none;
  border-color: #bbb;
  background-color: #ddd;
}
.cust-checkboxes [type="checkbox"]:disabled:checked + label:after {
  color: #999;
}
.cust-checkboxes [type="checkbox"]:disabled + label {
  color: #aaa;
}

/*end*/

/*.loginModalWrap  .close{
  position: absolute;
  right: -10%;
  color: #fff;
  opacity: 1;
  font-size: 38px;
  top: -3%;
}*/
.loginModalWrap .formWrap{
  min-height: 252px;
}
.modal-backdrop.show{
  opacity: .8
}
.login-img {
  object-fit: cover;
  height: 100%
}

.modal-dialog {
    min-height: calc(100vh - 60px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: auto;
}

/* new css rule for desktop */
.img-responsive{max-width: 100%;}
/* end of new css rule */

@media(max-width: 768px) {
  .modal-dialog {
    min-height: calc(100vh - 20px);
  }
}
.login-error-msg {
  color: red;
}
.fadeAway {
  background: rgba(128, 128, 128, .3) !important;
}

/* new css rule for mobile */
@media(max-width: 768px) {
  .topBannerWrap{padding-top: 100px;}
  header .navbar-light .navbar-brand {
    margin-right: auto !important;
}
.topBannerWrap{padding-top:80px !important; padding-bottom: 10px !important;}
.topBannerWrap .leftSection{text-align: center;}

.topBannerWrap .discountInfo {
  padding: 10px 25px;
  margin-bottom: 30px;
  margin-top: 30px !important;
}
.topBannerWrap .buttonWrap{margin-bottom: 40px;}
header .navbar-light .navbar-brand img {
  max-width: 130px !important;
}
.mobile-display {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: hsla(0, 0%, 100%, .96);
  display: none;
}
.mobile-display ul {
      display: grid;
    position: absolute;
    top: 60px;
    left: 35px;
}
.mobile-display ul li{
 display: block;
 width: 100%;
 margin-bottom: 25px;
}
span.close-menu {
  position: absolute;
  z-index: 9999;
  color: #797979;
  font-size: 40px;
  right: 10px;
  top: 0px;
  -webkit-text-stroke: 7px #fff;
  cursor: pointer;
  display: none;
}
header .navbar-light .navbar-nav .nav-link{
  display: inline-block !important;
  align-items: normal !important;
  justify-content: normal;
  min-height: auto !important;
  padding: 5px 10px !important;
  transition: all 0.3s ease 0s;
  color: rgb(32, 33, 35);
  border: none !important;
  color: rgb(115, 115, 115) !important;
  }
  footer img {
    max-width: 160px !important;
}
.navbar{
  padding: 1rem 1rem !important;
}
span.navmob-icons {
  font-size: 25px;
  color: #0041FF;
}
.mainloginWrapper {
  position: relative;
  width: 450px;
  margin: auto;
  padding-top: 65px !important;
  max-width: 100%;
  padding: 0px 20px;
}
.innerwrapper{
  padding: 40px !important;
}
.ml-auto.collapse.show {
  position: absolute !important;
  right: 6px !important;
  top: 5px !important;
}
.uploadDocumentEnterDocumentWrap .tabNav{
  flex: auto !important;
  max-width: 100% !important;
  background: #f7f7f7  !important;
  width: 100%  !important;
}
.uploadDocumentEnterDocumentWrap .tabWrap ul{
  display: flex;
}
}


/* end of new css rule for mobole */

.maincustomwrapper{
  display: block;
  width: 100%;
  padding-top: 75px;
}
  .topBannerWrap .discountInfo{
    display:none !important;
}
.form-control{
  height: 55px !important;
}
@media(min-width:768px){
  .mobilemenu{
    display: none;
  }
}
.hide-leftnav .navbar{
  padding: 1.5rem 1rem !important;
}
.invalid{
  top: 52px !important;
}
.custom_errorpolicy{
  top: 25px !important;
}
.resend_code {
  text-align: center;
}
.resend_code a {
  font-size: 14px;
}
.text-center{
  text-align: center;
}

#notfound {
  position: relative;
  height: 100vh;
}

#notfound .notfound {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.notfound {
  max-width: 560px;
  width: 100%;
  padding-left: 160px;
  line-height: 1.1;
}

.notfound .notfound-404 {
  position: absolute;
  left: 0;
  top: 0;
  display: inline-block;
  width: 140px;
  height: 140px;
  background-image: url('../images/emoji.png');
  background-size: cover;
}

.notfound .notfound-404:before {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-transform: scale(2.4);
      -ms-transform: scale(2.4);
          transform: scale(2.4);
  border-radius: 50%;
  background-color: #f2f5f8;
  z-index: -1;
}

.notfound h1 {
  font-family: 'Nunito', sans-serif;
  font-size: 65px;
  font-weight: 700;
  margin-top: 0px;
  margin-bottom: 10px;
  color: #151723;
  text-transform: uppercase;
}

.notfound h2 {
  font-family: 'Nunito', sans-serif;
  font-size: 21px;
  font-weight: 400;
  margin: 0;
  text-transform: uppercase;
  color: #151723;
}

.notfound p {
  font-family: 'Nunito', sans-serif;
  color: #999fa5;
  font-weight: 400;
}

.notfound a {
  font-family: 'Nunito', sans-serif;
  display: inline-block;
  font-weight: 700;
  border-radius: 40px;
  text-decoration: none;
  color: #388dbc;
}

@media only screen and (max-width: 767px) {
  .notfound .notfound-404 {
    width: 110px;
    height: 110px;
  }
  .notfound {
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 110px;
  }
}
.dropdown-divider{
  display: none !important;
}
.dropdown-item{
  padding: .65rem 1.5rem !important;
  border-bottom: 1px solid #0041FF !important;
  color:#0041FF !important;
}
.dropdown-item a{
  color:#0041FF !important;
}
.dropdown-menu{
  padding: 0rem 0 !important;
}
.dropdown-item:hover, .dropdown-item:focus {
  background-color: #0041FF !important;
  color: #fff !important;
}

.dropdown-item:hover a, .dropdown-item:focus a {
  color: #fff !important;
}
header .navbar-light .navbar-nav .nav-link:hover, header .navbar-light .navbar-nav .nav-link:focus {
  color: rgb(255, 255, 255);
  background-color: #0041FF !important;
}
.prifileform .invalid{
  top: 35px !important;
}
.fixed-header header{
  position: fixed;
}
.link-verify {
  margin: auto;
  width: 60%;
  text-align: center;
}
a.link-home {
  color: #00145a;
}
.serchicons {
  cursor: pointer;
}
.uploadLoader {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #0c0c0cad;
    z-index: 99999;
}