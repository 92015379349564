.unscWrapper {
    width: 776px;
    margin: auto;
    min-height: 83vh;
    padding-top: 130px;
}
ul{padding-left: 0px; margin-bottom: 0px;}
.custome_radio{font-weight: normal; font-size: 15px !important;}
.untitletxt {
    color: #00145A;
    font-size: 30px;
    font-weight: 700;
    text-transform: capitalize;
    padding-bottom: 15px;
}
ul li{list-style: none;position: relative;}

.unscWrapper ul li{margin-bottom: 10px;}
.unscbtn, .unsubCancel {
    background: #0041FF;
    color: #fff;
    padding: 10px 15px;
    font-size: 15px;
    margin: 20px 0px;
    display: inline-block;
    border-radius: 4px;
    cursor: pointer;
}
.customun_scbtn{
    background: #d7e7ff;
    color: #0041ff;
    padding: 10px 15px;
    font-size: 15px;
    margin: 20px 15px;
    display: inline-block;
    border-radius: 4px;
    cursor: pointer;
}


.subpopup {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.6);
    z-index: 9;
}
.subpopupbodu {
    position: absolute;
    top: 50%;
    left: 50%;
    background: #fff;
    transform: translate(-50%,-50%);
    padding: 10px 60px;
    border-radius: 20px;
    text-align: center;
}
.sub_img {
    padding-bottom: 30px;
}

span.closebtn {
    font-size: 50px;
    position: absolute;
    -webkit-text-stroke: 8px #fff;
    right: 3px;
    top: -16px;
    cursor: pointer;
}

/* custome radio button */
.custome_radio {
    display: block;
    position: relative;
    padding-left: 25px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  
  /* Hide the browser's default radio button */
  .unscWrapper .custome_radio input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }
  
  /* Create a custom radio button */
  .unscWrapper .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    border-radius: 50%;
    border: solid 1px #00145a;
  }
  
  /* On mouse-over, add a grey background color */
  .unscWrapper .custome_radio:hover input ~ .checkmark {
    background-color: #00145a;
  }
  
  /* When the radio button is checked, add a blue background */
  .unscWrapper .custome_radio input:checked ~ .checkmark {
    background-color: #00145a;
  }
  
  /* Create the indicator (the dot/circle - hidden when not checked) */
  .unscWrapper .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }
  
  /* Show the indicator (dot/circle) when checked */
  .custome_radio input:checked ~ .checkmark:after {
    display: block;
  }
  
  /* Style the indicator (dot/circle) */
  .custome_radio .checkmark:after {
       top: 9px;
       left: 9px;
       width: 8px;
       height: 8px;
       border-radius: 50%;
       background: #00145a;
  }

  .unsccontainer {
    box-shadow: 0px 1px 2px 2px #ccc;
    padding: 30px;
    border-radius: 10px;
}