ul{padding-left: 0px; margin-bottom: 0px;}
ul li{list-style: none;}
.subwrapper{}
.topheader{}
.topheadleft{
    float: left;
}
.topheadleft img{}
.topright{
    float: right;
}
.topright ul{}
.topright ul li{
    display: inline-block;
    padding: 10px;
}
.topbanner{
    clear: both;
    text-align: center;
}
.topbanner .bannertxt{
    color:
#00145A;
    font-size: 48px;
    font-weight: 700;
}
.plancontainer {
    display: inline-block;
    width: 100%;
    margin: auto;
    padding-top: 150px;
}

.planewconatnt {
    display: block;
    width: 80%;
    margin: auto;
}
.topbanner p{
    font-size: 24px;
    color: #00145A;
    width: 77%;
    margin: auto;
}
.basicplan{
  float: left;
  width: 50%;
  padding: 15px 30px;
  text-align: center;
}
.plancontainer {}
.premiumplan{
    float: right;
    width: 50%;
    padding: 15px 30px;
    text-align: center;
}
.palncontantwrap{
    border: solid 1px #fff;
    box-shadow: 0 1px 3px 0px rgba(0,0,0,0.2);
    padding: 20px 10px;
}
.planhead{
    color:
#00145A;
    font-size: 36px;
    font-weight: 600;
}
.plndes{}
.plandes ul{
    text-align: left;
    width: 79%;
    margin: auto;
}
.plandes ul li{
    font-size: 18px;
    color: #00145A;
    line-height: 33px;
}
.plndes{
    color:
#00145A;
    font-size: 24px;
}
.veiwmore{
    color:
#00145A;
    font-size: 24px;
    text-decoration: underline;
    padding: 25px 0px;
}
.priecontant{
    position: relative;
    color: #00145A;
}
.prcamount{
    font-size: 24px;
    font-weight: 600;
    color: #00145A;
    padding-right: 5px;
}
.permnt{}
.chooseplan{}
.plantaxt{
    margin: 0px;
    padding: 20px 0px;
    color: #00145A;
    font-size: 24px;
}
.chooseplan select{height: 40px;width: 328px;border-radius: 4px;border: solid 1px rgba(0,0,0,0.38);}
span.planbutton {
  background: #0041ff;
  color: #fff;
  margin: 10px 0px;
  display: inline-block;
  font-size: 20px;
  padding: 5px 15px;
  border-radius: 4px;
  cursor: pointer;
}
.monbtn{}
.planbtmtxt{
    clear: both;
    font-size: 24px;
    color:
#666666;
    font-weight: 300;
    width: 62%;
    margin: auto;
    text-align: center;
    padding: 25px 0px;
}
.paymentinfo{}
.paytxt{
    font-size: 46px;
    color:
#00145A;
    text-align: center;
    width: 85%;
    margin: auto;
    padding: 25px 0px;
    line-height: 65px;
}
.paydes{
    text-align: center;
    font-size: 24px;
    color:
#00145A;
    font-weight: 300;
    margin-bottom: 0px;
    padding: 25px 0px;
}
.sumpayinfocontainer{
    overflow: hidden;
    padding: 25px;
}
.summary{
    float: left;
    width: 40%;
    padding: 0px 15px;
    border-right: solid 1px #ccc;
}
.sumcontant{}
.smtxt{
    color:
#00145A;
    font-size: 14px;
    padding-bottom: 5px;
}
.sumtxtdes{
    padding: 10px;
    background: #F6F6F8;
    border-radius: 5px;
    width: 48%;
}
.freetxt{
    color:
#00145A;
    font-weight: 600;
    font-size: 20px;
    margin: 0px;
}
.freedes{
    color:
#00145A;
    font-size: 12px;
    margin-bottom: 0px;
}
.paymentinformation{
    float: right;
    width: 60%;
    padding: 0px 15px;
}
.sumpaytxt{
    font-size: 24px;
    color: #00145A;
    font-weight: 500;
    margin-bottom: 20px;
}
.formcontainer{}
.formgroup{
    margin-bottom: 15px;
}
.frmleft{
    display: inline-block;
    width: 49%;
    padding:0px  10px;
    position: relative;
}
.frmright{
    display: inline-block;
    width: 49%;
    padding-left: 10px;
    position: relative;
}
.termcon{}
.termcheck{
    margin-right: 5px;
}
.paynowbtn{
    display: inline-block;
    background: #0041ff;
    font-size: 24px;
    color: #fff;
    padding: 2px 15px;
    border-radius: 4px;
    margin-top: 10px;
    cursor: pointer;
}
/* popup css */
.planpopup {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, .8);
  z-index: 99;
}
.planpopupbody {
  position: absolute;
  background: #fff;
  width: 60%;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);
  border-radius: 5px;
  padding: 25px;
}
.plclose{
    position: absolute;
    top: -5px;
    right: 7px;
    font-size: 35px;
    cursor: pointer;
    -webkit-text-stroke: 4px #FFF;
}
.leftptxt{
    font-size: 48px;
    color:
#00145A;
    font-weight: 500;
}
.filtxtp{
    font-size: 24px;
    color: #00145A;
    font-weight: 400;
}
.popprice{
    color: #00145A;
}
.popmnt{}
.pop_paysch{
    font-size: 20px;
    color:
#00145A;
    padding: 0px 0px 15px;
}
.popselect{}
.popselect select{
    width: 85%;
    padding: 5px;
    border-radius: 4px;
}
.popmonthpln{background: #0041ff;color: #fff;width: 85%;text-align: center;margin: 10px 0px;font-size: 20px;padding: 5px;border-radius: 4px;}
.popleftcontant {
  float: left;
  width: 50%;
}
.poprightcontant{
    float: right;
    width: 50%;
}
span.ptxt {
  font-size: 24px;
  font-weight: 700;
  display: inline-block;
  padding-right: 2px;
  padding: 10px 0px;
}
.pritxt{
    font-size: 24px;
    color: #00145A;
    padding-top: 30px;
}
.poprightcontant ul{}
.poprightcontant ul li{
    color: #00145A;
    padding: 5px 0px;
}
.poprightcontant ul li span{}
  .sucessfuulcontantnew {
      margin: auto;
      width: 60%;
      text-align: center;
  }

.rightcheck{
    font-size: 150px;
    color: #00145A;
}
.paysustxt{
    color: #00145A;
    font-size: 48px;
    font-weight: 700;
}
.patmentdec{
    font-size: 24px;
    color: #00145A;
    font-weight: 400;
}
.dashboardlink{}
span.dashbtn {
  background: #0041FF;
  color: #fff;
  padding: 10px 15px;
  font-size: 15px;
  margin: 20px 0px;
  display: inline-block;
  border-radius: 4px;
  cursor: pointer;
}

.sucessfullwrapper{
    display: block;
    width: 100%;
    padding-top: 110px;
}
.sucessfuulcontant{
    position: relative;
    text-align: center;
    width: 60%;
    margin: auto;

}
.rightcheck{
    font-size: 150px;
    color: #00145A;
}
.paysustxtnw{
    color: #00145A;
    font-size: 60px;
    font-weight: 700;
    letter-spacing: 4px;
}
.patmentdecnw{
    font-size: 25px;
    color: #00145A;
    font-weight: 400;
    letter-spacing: 2px;
    text-transform: capitalize;
}
.dashboardlink{}
span.dashbtn {
  background: #0041FF;
  color: #fff;
  padding: 10px 15px;
  font-size: 15px;
  margin: 20px 0px;
  display: inline-block;
  border-radius: 4px;
  cursor: pointer;
}
.dashboarditem {
    padding: 20px;
    text-transform: capitalize;
}
.dashmainwrapper{
    padding: 10px;
    overflow: auto;
}
.dashplanwrapper {
    text-align: left;
    width: 50%;
    margin: auto;
    box-shadow: 0.5px 0.5px 3.5px 0.5px #ccc;
    margin-bottom: 35px;
    padding: 0px;
}
    .leftitem_dash{
    display: inline-block;
    width: 49%;
    text-align: left;
}
.rightitem_dash{
    display: inline-block;
    width: 49%;
    text-align: right;
}
.dashitem {
    display: block;
    padding: 10px;
    border-bottom: solid 1px #ddd;
    background: #f8f9fa;
    margin-bottom: 5px;
}
input.newsub_Btn {
    padding: 10px 30px;
    font-size: 25px;
    margin: 20px 0px;
    display: inline-block;
    border-radius: 4px;
    cursor: pointer;
    border: none;
}
.customerFormInput {
  margin-bottom: 0.8rem;
}
.customerError {
  position: absolute;
  font-size: 11px;
  color: #bd3330;
}
.form-control {
  border: solid 1px #b7acac !important;
}
.controls {
  margin-bottom: 10px;
}
.subs_timestamp {
  font-size: 12px;
  color: #666666;
  float: right;
}
.subs_block {
  overflow: hidden;
}
