/* custome radio button */
.customradio {
}

/* Hide the browser's default radio button */
.customradio input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom radio button */
.paymentitems .checkmark {
  position: absolute;
  top: -4px;
  right: -30px;
  height: 25px;
  width: 25px;
  background-color: #fff;
  border-radius: 50%;
  border: solid 1px #2196f3;
}

/* On mouse-over, add a grey background color */
.paymentitems .customradio:hover input ~ .checkmark {
  background-color: #2196f3;
}

/* When the radio button is checked, add a blue background */
.paymentitems .customradio input:checked ~ .checkmark {
  background-color: #2196F3;
  border: solid 1px #2196F3;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.paymentitems .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.paymentitems .customradio input:checked ~ .checkmark:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.paymentitems .customradio .checkmark:after {
     top: 9px;
     left: 9px;
     width: 0px;
     height: 0px;
     border-radius: 50%;
     background: white;
}


/* css for paymentmethod  */
.paymentitems .paymentwrapper{
  width: 534px;
  margin: auto;
  padding: 10px;
}
.paymentitems .paymentcontant{
    background: #e7eaf1;
    overflow: hidden;
    padding: 20px;
}
.paymentitems .paymenthead{
    background: #ccc;
    padding: 10px 10px;
    margin-bottom: 20px;
    font-weight: 700;
    font-size: 16px;
    text-align: center;
}
.leftarrow{
    float: left;
    cursor: pointer;
}
.paymentbody{

}
.paymentitems{margin-bottom: 15px;position: relative;overflow: hidden;}
.paymentimg{padding: 10px;}
.imgcontaniner{
  display: inline-block;
  width: 100%;
  vertical-align: middle;
  overflow: hidden;
  border-radius: 15px;
}
.customradio{position: relative;bottom: 32px;right: -40px;}
.carimg{
    display: block;
    width: 100%;
    border-radius: 15px;
}
.paymentinfo{display: inline-block;width: 100%;vertical-align: middle;}
.addcard{text-align: center;}
input.addcardbtn {
  border: none;
  background: #5a7af1;
  color: #fff;
  padding: 10px 30px;
  border-radius: 5px;
  cursor: pointer;
}
.paymentinfocontainer {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #07070733;
  border: solid;
  color: #fff;
  padding: 15px;
  border-radius: 15px;
  overflow: hidden;
}
.carttitletxt{
    color: #fff;
    font-size: 15px;
}
.cardnumber{
    font-size: 25px;
    text-align: center;
    padding: 40px 0px;
}
.paymentinfo{}
.carholdernatxt{
    font-weight: 300;
    font-size: 13px;
}
.carholname{
    font-size: 13px;
    font-weight: 600;
}
.paymentcontant{
  background: #e7eaf1;
  overflow: hidden;
  padding: 20px;
}
.paymenthead{
  background: #ccc;
  padding: 10px 10px;
  margin-bottom: 20px;
  font-weight: 700;
  font-size: 16px;
  text-align: center;
}
.payment-page-error {
  border: 1px solid #c23d4b;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* end of paymentmethod css */
