@media only screen and (max-width: 991.5px){
   .topBannerWrap .headingWrap h1{
      font-size:50px;
   }
   .topBannerWrap .buttonWrap .btnBlue,
   .topBannerWrap .buttonWrap .btnTransparent{
      min-width: 125px;
   }
    header .navbar-light .navbar-brand{
      margin-right: 0px;
   }
   /*header .navbar-light .navbar-nav {
      flex-direction: row;
   }*/
   
  
   .tab-dis-none{
      display: none;
   }
   .loginRegisterFormWrap{
      width: 100%;
      max-width: 100%;
   }
   header .navbar-nav{
      flex-direction: row;
   }
}
@media only screen and (max-width: 767.5px) {
   .topBannerWrap .rightSection{
      display: none;
   }
   .howItWorkWrap .innerleftsec{
      position: relative;
      width: 100%
   }
   .topBannerWrap .leftSection{
      width: 100%;
   }
   .topBannerWrap{
      padding-top: 135px;
   }
   .howItWorkWrap .innerRightSection {
    margin-left: 0%;
    width: 100%;
   }
   .order-mobile-1{
      order:1;
   }
   .order-mobile-0{
      order:0;
   }
      header .navbar-light .navbar-nav .nav-link{
         min-height: 42px;
         width: 110px;
      }
      header .navbar-light .navbar-nav .nav-item{
         padding-right: 10px;
      }
      .ourServicesWrap h2, .pricinSecWrap h2, .faqWrap h2, .freeTrialWrap h2{
         margin-bottom: 30px;
      }

      .topBannerWrap{
         padding-bottom: 70px;
      }
      .ourServicesWrap {
          padding-bottom: 60px;
      }
      .ourServicesWrap.ourFeaturesWrap{
         padding-top: 60px;
      }
      .pricinSecWrap{
         padding-top: 20px;
      }
      .clientsCommentWrap {
         padding: 0px 0px 0px;
      }
      .clientsCommentWrap .carousel-control-next, .clientsCommentWrap .carousel-control-prev{
         bottom: -15px;
      }
      .whanttoKnowWhenLaunch{
         padding-top: 0px;
      }
}
@media only screen and (max-width: 567.5px) {
   .uploadDocumentEnterDocumentWrap .tabWrap{
      flex-wrap: wrap;
   }
   .uploadDocumentEnterDocumentWrap .tabNav{
      flex: 0 0 100%;
      max-width: 100%;
   }
   .uploadDocumentEnterDocumentWrap .tabNav li a{
      padding:  25px 20px;
   }
   .uploadDocumentEnterDocumentWrap .tabNav li a.activelink, .uploadDocumentEnterDocumentWrap .tabNav li a:hover{
      border-bottom: 4px solid rgb(82, 104, 219);
      border-left: 0px solid rgb(235, 235, 235);  
   }
   .uploadDocumentEnterDocumentWrap .tabNav li a{
      border-right: 0px;
   }
   .uploadDocumentEnterDocumentWrap .tabNav li a{
      border-left: 0px solid #ddd;
   }
   .fillNowanchorWrap{
      flex-wrap: wrap;
      justify-content: center;
   }
   .fillNowanchorWrap input{
      flex: 0 0 100%;
      margin-right: 0px;
      margin-bottom: 10px;
   }
   .browseForDocumentBtn{
      word-wrap: break-word;
      white-space: normal;
   }
   .uploadDocumentEnterDocumentWrap .list{
      padding: 15px;
   }
   .tabContentWrap .clickme2 a{
      height: 53px;
      width: 74px;
   }
   .loginRegisterFormWrap h2{
      font-size: 30px;
   }
   .loginRegisterFormWrap{
      padding: 25px;
   }
   .loginRegisterFormWrap .loginWithGoogleBtn img{
      margin-right: 7px;
   }
   .loginForgetWrap a{
      margin-left: 0px;
   }
   .loginForgetWrap{
      text-align: center;
   }
   .loginForgetWrap a{
      text-align: center;
      margin-top: 10px;
      display: block;
   }
}
@media only screen and (max-width: 400px) {
   .subscribeInputWrap > div{
      padding-right: 0px;
      flex: 0 0 100%;
      max-width: 100%;
      padding-bottom: 15px;
   }
   .subscribeInputWrap{
      justify-content: center;
      flex-wrap: wrap;
   }
   .topBannerWrap{
      padding-top: 160px;
   }
}